import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';

import './filter-location.scss'
import PxdInputForm from '../../../../../../app-shared/components/stateful/pxd-form/pxd-input-form/pxd-input-form';
import PxdDropdownForm from '../../../../../../app-shared/components/stateful/pxd-form/pxd-dropdown-form/pxd-dropdown-form';
import PxdTags from '../../../../../../app-shared/components/stateful/pxd-tags/pxd-tags';
import PxdCheckboxForm
    from "../../../../../../app-shared/components/stateful/pxd-form/pxd-checkbox-form/pxd-checkbox-form";
import ReferentialService from "../../../../../../config/services/referential.service";

class FilterLocation extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            checkboxes: [
            {
                name: this.props.intl.formatMessage({id: 'common.abroad'}),
                key: "abroad",
                value: this.props.criteria.abroad
            }
            ]
        };

        this.handleCityValueChange = this.handleCityValueChange.bind(this);
        this.selectTownAutoComplete = this.selectTownAutoComplete.bind(this);
        this.getTowns = debounce(this.getTowns,500);
    }

    handleCityValueChange(e,key){
        if(e.target.value.length >= 2){
            this.getTowns(e.target.value);
        } else {
            this.setState({autocompleteTowns : []});
        }
    }

    getTowns = (value) =>{
        ReferentialService.getTowns(value).then((response) =>{
            this.setState({autocompleteTowns: response.data});
        })

    };

    selectTownAutoComplete(e, key, option){
        e.target.value = option.nameTown;
        this.props.handleChangeDropDown(e,key)
    }



    render() {
        return (
            <div className="offers-filter offers-filter_location">
                <h2 className="mobile-only filter-title">
                    <span className="icon-location"/>
                    <FormattedMessage id="offers.filter.location" />
                </h2>
                <div className="offers-filter-form offers-filter_location-form">
                    <div className="select-city">
                        <PxdInputForm placeholder={this.props.intl.formatMessage({ id: 'search.advanced.location.city.placeholder' })}
                                      ariaLabel={this.props.intl.formatMessage({ id: 'search.advanced.location.city.ariaLabel'})}
                                      handleEnterKeyPress={this.props.handleChangeDropDown}
                                      handleButtonClick={this.props.handleButtonClick}
                                      keyName={'cities'} buttonAdd="true"
                                      handleChangeAutoComplete={this.selectTownAutoComplete}
                                      autocompleteOptions={this.state.autocompleteTowns}
                                      handleChange={this.handleCityValueChange}/>
                    </div>
                    <div className="select-department">
                        <PxdDropdownForm placeholder={this.props.intl.formatMessage({ id: 'search.advanced.location.department.placeholder'})}
                                         options={this.props.referentialDepartments}
                                         handleChange={this.props.handleChangeDropDown}
                                         keyName={'departments'}
                                         ariaLabel={this.props.intl.formatMessage({ id: 'search.advanced.location.department.ariaLabel'})}
                                         customClass="form-input-empty"/>
                    </div>
                    <div className="select-region">
                        <PxdDropdownForm placeholder={this.props.intl.formatMessage({ id: 'search.advanced.location.district.placeholder'})}
                                         options={this.props.referentialRegions}
                                         handleChange={this.props.handleChangeDropDown}
                                         keyName={'regions'}
                                         ariaLabel={this.props.intl.formatMessage({ id: 'search.advanced.location.district.ariaLabel'})}
                                         customClass="form-input-empty"/>
                    </div>
                </div>
                <div className="offers-filter-tags offers-filter_location-tag">
                    <PxdTags tags={this.props.tags.filter(e => e.name !== this.props.intl.formatMessage({id: 'common.abroad'}))}
                             handleTagRemove={this.props.handleTagRemove}/>
                </div>
                <div className="offers-filter-form offers-filter_location-form foreign-switch-wrapper">
                    <PxdCheckboxForm checkboxes={this.state.checkboxes} customClass="checkbox-switch"
                                     handleChange={this.props.handleCheckboxChange}/>
                </div>
            </div>

        );
    }
}

export default injectIntl(FilterLocation);
