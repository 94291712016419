import React from "react";

function PxdButton(props) {
    const { className = "", onClick = () => {}, label = "", text = "" } = props;
    return (
        <button aria-label={label} type="button" className={`pxd-button btn-rounded ${className}`} onClick={onClick}>
            {text}
        </button>
    );
}

export default PxdButton;
