import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { IntlProvider } from "react-intl";
import messagesFr from "../config/i18n/messages_fr";
import MainFrontal from "./main-frontal";
import HttpInterceptor from "../app-shared/components/utils/http/http.interceptor";

import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/fr";

const messages = {
    fr: messagesFr
};

const language = "fr";

const App = () => {
    const basename = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME.replace(/ /g, "") : "/";

    return (
        <div className="app">
            <IntlProvider locale={language} messages={messages[language]}>
                <Router basename={basename}>
                    <HttpInterceptor>
                        <MainFrontal />
                    </HttpInterceptor>
                </Router>
            </IntlProvider>
        </div>
    );
};

export default App;
