import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import PageContentService from "../../../../config/services/page-content.service";
import Footer from "../../../../theme/components/footer/footer";
import OfferListHeader from "../offer-list/internal-components/offer-list-header/offer-list-header";
import { AccessibilityContext } from "../../utils/accessibility-context";
import "./conditions-generales-utilisation.scss";
import ScrollToTop from "../../../../theme/components/scroll-to-top/scroll-to-top";

const CguHelper = () => {
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);
    const [cgu, setCgu] = useState("");

    useEffect(() => {

        PageContentService.getCurrent("cgu").then(rsp => {
            setCgu(
                sanitizeHtml(rsp.data[0].content, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h1", "h2", "sup"])
                })
            );
        });
    }, []);

    return (
        <div className={`cgu-helper ${accessibilityIsActivated ? "accessible" : ""}`}>
            <ScrollToTop />
            <OfferListHeader pageName="CGU " title="Conditions générales d'utilisation" />
            <article className="cgu-helper__content">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: cgu }} />
            </article>
            <Footer />
        </div>
    );
};

export default CguHelper;
