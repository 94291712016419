import api from "../../../app-shared/components/utils/http/http-service";

const getCurrent = page => {
    return api.get(`/content?page=${page}`);
};

const updatePageContent = pageContent => {
    return api.put("/admin/content", pageContent);
};

export { getCurrent, updatePageContent };
