import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../../page/home/home";
import OfferList from "../../page/offer-list/offer-list";
import Offer from "../../page/offer/offer";
import SearchAdvanced from "../../page/search-advanced/search-advanced";
import GenericNotFound from "../../page/not-found/not-found";
import PrivacyPolicy from "../../page/privacy-policy/privacy-policy";
import AccessiblityHelper from "../../page/accessibility-helper/accessibility-helper";
import ConditionsGeneralesUtilisations from "../../page/conditions-generales-utilisation/conditions-generales-utilisation";
import SiteMap from "../../page/sitemap/sitemap";
import ApplicationForm from "../../page/application-form/application-form";

function SwitchRouter() {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/admin" component={() => (window.location = "/admin/")} />
            <Route exact path="/search" component={SearchAdvanced} />
            <Route exact path="/offers" component={OfferList} />
            <Route exact path="/offer/:offerUrl" component={Offer} />
            <Route exact path="/offer/:offerUrl/apply" component={ApplicationForm} />
            <Route exact path="/mentions-legales" component={PrivacyPolicy} />
            <Route exact path="/conditions-generales-utilisation" component={ConditionsGeneralesUtilisations} />
            <Route exact path="/accessibilite" component={AccessiblityHelper} />
            <Route exact path="/plan-site" component={SiteMap} />
            <Route exact path="/404" component={GenericNotFound} />
            <Route exact component={GenericNotFound} />
        </Switch>
    );
}

export default SwitchRouter;
