import qs from "query-string";
import api from "../../../app-shared/components/utils/http/http-service";

class UserService {
    static getCurrent() {
        return api.get("/admin/users/current");
    }

    static hasRole(user, roles) {
        let has = false;
        if (user && roles) {
            if (Array.isArray(roles)) {
                roles.forEach((role) => {
                    if (this.hasThisRole(user, role)) {
                        has = true;
                    }
                });
            } else if (this.hasThisRole(user, roles)) {
                has = true;
            }
        }

        return has;
    }

    static hasThisRole(user, role) {
        let has = false;

        if (user?.roles && Array.isArray(user.roles) && role) {
            user.roles.forEach((r) => {
                if (r.role === role) {
                    has = true;
                }
            });
        }
        return has;
    }

    static createOrUpdateUser(user) {
        return api.put("/admin/users", user);
    }

    static getUsers(criteria, sort, range) {
        const query = qs.stringify({ ...criteria, ...sort, ...range });

        return api.get(`/admin/users?${query}`);
    }

    static getUsersLight(criteria, sort, range) {
        const query = qs.stringify({ ...criteria, ...sort, ...range });

        return api.get(`/admin/users/light?${query}`);
    }

    static getUser(userId) {
        return api.get(`/admin/users/${userId}`);
    }

    static createOrUpdateUserPassword(userPassword) {
        return api.put("/admin/users/password", userPassword);
    }

    static createOrUpdateUserPasswordToken(userPasswordToken) {
        return api.put("/users/password-token", userPasswordToken);
    }

    static resetPassword(userEmail) {
        return api.put("/users/password-reset", userEmail);
    }

    static getTokenValidity(token) {
        return api.put("/users/token-validity", token);
    }
}

export default UserService;
