import React from "react";

import "./notifications.scss";

import NotificationService from "./notification.service";
import NotificationItem from "./notification-item";

class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            iterator: 0,
        };

        this.addNotification = this.addNotification.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
    }

    componentWillMount() {
        NotificationService.addChangeListener(this.addNotification);
    }

    componentWillUnmount() {
        NotificationService.removeChangeListener(this.addNotification);
    }

    addNotification(notification) {
        notification.id = this.state.iterator;

        this.setState({
            notifications: [...this.state.notifications, notification],
            iterator: this.state.iterator + 1,
        });

        setTimeout(() => {
            this.setState({
                notifications: this.state.notifications.filter((n) => n.id !== notification.id),
            });
        }, 10000);
    }

    closeNotification(index) {
        const { notifications } = this.state;
        notifications.splice(index, 1);
        this.setState({ notifications });
    }

    render() {
        return (
            <div className={"notification-list"}>
                {this.state.notifications.map((notification, index) => (
                    <NotificationItem
                        notification={notification}
                        index={index}
                        handleButtonClose={this.closeNotification}
                        key={notification.id}
                    />
                ))}
            </div>
        );
    }
}

export default Notifications;
