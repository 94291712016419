import React from 'react';
import './pxd-button.scss';

const PxdButton = (props) => {
    
    return (
        <button type="button" onClick={props.handleButtonClick} className={`${props.customClass}`} disabled={props.isDisabled}>
            {props.name}
        </button>
    
    );
  };

export default PxdButton;