import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./offer-content.scss";
import Icon from "react-multi-date-picker/components/icon";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Calendar } from "react-multi-date-picker";
import { useHistory } from "react-router-dom";
import PxdCard from "../../../app-shared/components/stateless/pxd-card/pxd-card";
import gregorian_fr from "../../../app-admin/offers/pages/create-offer/pxd-form-contract/gregorian_fr/gregorian_fr";
import OfferService from "../../../app-admin/offers/services/offer.service";
import OfferTypeEnum from "../../../utils/enum/offer-type-enum";
import { getFirstDate, getLastDate } from "../../../utils/functions/functions";
import OfferStatusId from "../../../app-shared/components/utils/constants/offer-status-id";
import slugify from "../../utils/parse.helpers";

function OfferContent(props) {
    const { offer, cardInfo, mailto, searchUrl } = props;
    const { t } = useTranslation();

    const [datePickerValues, setDatePickerValues] = useState([]);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [offerStatusMSDT, setOfferStatusMSDT] = useState(null);

    const history = useHistory();
    function getDatePickerValues(weeks) {
        if (weeks !== undefined) {
            setDatePickerValues(JSON.parse(weeks));
        }
    }

    useEffect(() => {
        if (offer !== undefined) {
            getDatePickerValues(offer.weeksList);
        }
    }, [offer]);

    useEffect(() => {
        if (
            offer.offerId !== undefined &&
            offer.offerType.nameOfferType === OfferTypeEnum.junior_internship &&
            offer.adminOfferId &&
            offer.offerStatusId !== OfferStatusId.DRAFT &&
            offer.offerStatusId !== OfferStatusId.TO_VALIDATE
        ) {
            OfferService.getOfferStatus(offer.offerId)
                .then((rsp) => {
                    if (rsp.data === 1) {
                        setOfferStatusMSDT(true);
                    } else if (rsp.data === 2 || rsp.data === 3 || rsp.data === 4) {
                        setOfferStatusMSDT(false);
                    } else {
                        setOfferStatusMSDT(null);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    const handleCalendarToggle = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const goToApplicationForm = () => {
        const url = `${slugify(offer.title)}-${offer.offerId}`;
        history.push({
            pathname: `/offer/${url}/apply`,
            state: { offer, searchUrl },
        });
    };

    const monthKey = offer.offerMonth ? offer.offerMonth.toLowerCase() : null;
    const offerMonth = monthKey ? t(`month.${monthKey}`) : "";

    return (
        <section className="offer-content">
            <aside className="offer-content__aside">
                <PxdCard title={t("offer-front.card-title")} infoCard={cardInfo} />
            </aside>
            <div className="offer-content__button">
                {mailto && (
                    <a onClick={goToApplicationForm} className="btn-rounded">
                        {t("offer-front.apply")}
                    </a>
                )}
            </div>
            <div className="offer-content__header">
                {offer.offerType && offer.offerType.nameOfferType !== OfferTypeEnum.caj && (
                    <span className={`offer-content__header__tag offer-content__header__tag--${offer.offerType.nameOfferType}`}>
                        {offer.offerType.titleOfferType.toUpperCase()}
                    </span>
                )}
                {offer.offerType && offer.offerType.nameOfferType === OfferTypeEnum.caj && (
                    <span className={`offer-content__header__tag offer-content__header__tag--${offer.offerType.nameOfferType}`}>
                        {t("offer-front.contract")}
                    </span>
                )}

                {offer.offerType && offer.offerType.nameOfferType !== OfferTypeEnum.junior_internship && (
                    <div className="offer-content__header__date">
                        {t("offer-front.starting")}
                        {` ${offerMonth}`}
                        {` ${offer.offerYear}`}
                    </div>
                )}

                {offer.offerType && offer.offerType.nameOfferType === OfferTypeEnum.junior_internship && (
                    <div className="offer-content__header__date">
                        {`${t("offer-front.starting-from")} `}
                        {offer.weeksList && t(`${getFirstDate(offer.weeksList)}`)}
                        {` ${t("offer-list.until")} `}
                        {offer.weeksList && t(`${getLastDate(offer.weeksList)}`)}
                    </div>
                )}
                {offer.offerType && offer.offerType.nameOfferType === OfferTypeEnum.junior_internship && offer.adminOfferId && (
                    <div className="offer-content__header__status-msdt">
                        {offerStatusMSDT != null && t("offers:single-offer.statusMSDT")}
                    </div>
                )}
                {offer.offerType && offer.offerType.nameOfferType === OfferTypeEnum.junior_internship && offer.adminOfferId && (
                    <div className="offer-content__header__status-msdt-chip">
                        <span
                            className={(() => {
                                switch (offerStatusMSDT) {
                                case null:
                                    return "dot gray_dot";
                                case true:
                                    return "dot green_dot";
                                case false:
                                    return "dot red_dot";
                                default:
                                    return "";
                                }
                            })()}
                        />
                    </div>
                )}
                <div className="offer-content__header__reference">{`Ref - ${offer.reference} `}</div>
            </div>
            <article className="offer-content__description">
                <div className="offer-content__description__week">
                    {offer.offerType && offer.offerType.nameOfferType === OfferTypeEnum.junior_internship && (
                        <span>{t("offer-front.calendar")}</span>
                    )}
                    {offer.offerType && offer.offerType.nameOfferType === OfferTypeEnum.junior_internship && (
                        <Icon onClick={handleCalendarToggle} />
                    )}
                </div>
                {isCalendarOpen && offer.offerType && offer.offerType.nameOfferType === OfferTypeEnum.junior_internship && (
                    <div>
                        <Calendar
                            render={<Icon />}
                            calendarPosition="bottom-right"
                            locale={gregorian_fr}
                            format="DD/MM/YYYY"
                            value={datePickerValues}
                            multiple
                            range
                            weekStartDayIndex={1}
                            displayWeekNumbers
                            plugins={[<DatePanel key="date-panel-key" />]}
                            readOnly
                            mapDays={({ date }) => {
                                const isWeekend = [0, 6].includes(date.weekDay.index);

                                if (isWeekend)
                                    return {
                                        disabled: true,
                                        style: { color: "#ccc" },
                                    };
                            }}
                        />
                    </div>
                )}

                {offer.offerDescription && (
                    <div className="offer-section">
                        <h2 className="offer-section__title">{t("offer-front.description")}</h2>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: offer.offerDescription }} />
                    </div>
                )}
                {offer.globalDescription && (
                    <div className="offer-section">
                        <h2 className="offer-section__title">{t("offer-front.global")}</h2>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: offer.globalDescription }} />
                    </div>
                )}
                {offer.address && (
                    <div className="offer-section">
                        <h2 className="offer-section__title">{t("offer-front.address")}</h2>
                        {offer.address}
                        <br />
                        {offer.additionalAddress}
                        {offer.additionalAddress ? <br /> : ""}
                        {`${offer.postalCode} ${offer.city.toUpperCase()}`}
                    </div>
                )}
            </article>
        </section>
    );
}

export default OfferContent;
