import qs from "query-string";
import api from "../../app-shared/components/utils/http/http-service";
import OfferStatusIds from "../../app-shared/components/utils/constants/offer-status-id";

class OfferService {
    static getOffer(offerId, firstVisit) {
        return api.get(`/offers/${offerId}?first-visit=${firstVisit}`);
    }

    static countOffersNotClosed(criteriaState) {
        const criteria = { ...criteriaState };
        if (criteria.offerStatus.length === 0) {
            criteria.offerStatus = [OfferStatusIds.DRAFT, OfferStatusIds.PUBLISH, OfferStatusIds.SUSPENDED];
        } else {
            criteria.offerStatus = criteria.offerStatus.filter(element => element !== OfferStatusIds.CLOSED);
        }
        const query = qs.stringify({ ...criteria });

        return api.get(`/offers/count?${query}`);
    }

    static getOffers(criteria, sort, range) {
        const query = qs.stringify({ ...criteria, ...sort, ...range });

        return api.get(`/offers?${query}`);
    }

    static getOffersCount(criteria) {
        criteria.offerTypes = criteria.offerTypes.flat();
        const query = qs.stringify({ ...criteria });
        return api.get(`/offers/count?${query}`);
    }
}

export default OfferService;
