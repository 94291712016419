import React, { useState, useEffect } from "react";
import "./testimony-list.scss";
import { useTranslation } from "react-i18next";
import TestimonyCard from "../testimony-card/testimony-card";

const TestimonyCardList = props => {
    const { contents = [], className = "" } = props;
    const { t } = useTranslation();
    const [testimoniesConfig, setTestimoniesConfig] = useState([]);

    useEffect(() => {
        const newTestimoniesConfig = [];
        for (let i = 1; i <= 3; i += 1) {
            newTestimoniesConfig.push({
                avatar: contents[`testimony${i}.avatar`],
                firstname: contents[`testimony${i}.firstname`],
                age: contents[`testimony${i}.age`],
                organism: contents[`testimony${i}.organism`],
                offer: contents[`testimony${i}.offer`],
                content: contents[`testimony${i}.content`],
                longtext: contents[`testimony${i}.longtext`]
            });
        }
        setTestimoniesConfig(newTestimoniesConfig);
    }, [contents]);

    const generateTestimonyCard = testimonyConfig => {
        return (
            testimonyConfig.firstname &&
            testimonyConfig.organism && <TestimonyCard key={`${testimonyConfig.firstname}${testimonyConfig.organism}`} testimonyData={testimonyConfig} />
        );
    };

    return (
        <section className={`testimonies-section ${className}`}>
            <h2 className="testimonies-section__title">{t("home.testimony-title")}</h2>
            <ul className="testimonies-list">{testimoniesConfig && testimoniesConfig.length > 0 && testimoniesConfig.map(generateTestimonyCard)}</ul>
        </section>
    );
};

export default TestimonyCardList;
