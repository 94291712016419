import React from "react";
import "./pxd-switch.scss";

const PxdSwitch = props => {
    const { activated = false } = props;
    const { onClick = () => {} } = props;

    return (
        <button type="button" className={`pxd-switch ${activated ? "active" : ""}`} onClick={onClick}>
            <div className="moving-circle" />
        </button>
    );
};

export default PxdSwitch;
