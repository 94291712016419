import React, { useEffect, useState } from "react";
import "./filter-my-level.scss";
import { useTranslation } from "react-i18next";
import { OfferFiltersContext } from "../../offers-filter-context";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";

const FilterMyLevel = () => {
    const { t } = useTranslation();
    const { studyLevels, studyLevel, setStudyLevel } = React.useContext(OfferFiltersContext);
    const [studyLevelOptions, setStudyLevelOptions] = useState([]);

    useEffect(() => {
        const slo = studyLevels.sort((a, b) => a.order - b.order);
        setStudyLevelOptions(slo);
    }, [studyLevels]);

    const generateStudyLevelOption = data => {
        return (
            <option key={data.id} value={data.id}>
                {data.name}
            </option>
        );
    };

    return (
        <div className="filter-my-level-wrapper">
            <h3 className="title">{t("offer-list.my-level")}</h3>
            <div className="select-study-level-wrapper">
                <select className="select-study-level" value={studyLevel} onChange={e => setStudyLevel(e.target.value)}>
                    <option value="-1">{t("offer-list.choose-study-level")}</option>
                    {studyLevelOptions.map(generateStudyLevelOption)}
                </select>
                <PxdIcon iconName="arrow-bottom" />
            </div>
        </div>
    );
};

export default FilterMyLevel;
