import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import PageContentService from "../../../../config/services/page-content.service";
import Footer from "../../../../theme/components/footer/footer";
import OfferListHeader from "../offer-list/internal-components/offer-list-header/offer-list-header";
import { AccessibilityContext } from "../../utils/accessibility-context";
import ScrollToTop from "../../../../theme/components/scroll-to-top/scroll-to-top";
import "./privacy-policy.scss";

const PrivacyPolicy = () => {
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);
    const [privacyPolicy, setPrivacyPolicy] = useState("");

    useEffect(() => {

        PageContentService.getCurrent("legal-notice").then(rsp => {
            setPrivacyPolicy(
                sanitizeHtml(rsp.data[0].content, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h1", "h2", "sup"])
                })
            );
        });
    }, []);

    return (
        <div className={`privacy-policy ${accessibilityIsActivated ? "accessible" : ""}`}>
            <ScrollToTop />
            <OfferListHeader pageName="Mentions légales " title="Mentions légales" />
            <article className="privacy-policy__content">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
            </article>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
