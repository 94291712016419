import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PxdCheckboxForm from "../../../../../../app-shared/components/stateful/pxd-form/pxd-checkbox-form/pxd-checkbox-form";
import PxdDropdownForm from "../../../../../../app-shared/components/stateful/pxd-form/pxd-dropdown-form/pxd-dropdown-form";
import PxdTags from "../../../../../../app-shared/components/stateful/pxd-tags/pxd-tags";
import "./filter-contract.scss";

class FilterContract extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkboxes: [
                {
                    name: this.props.intl.formatMessage({ id: "offers.filter.contract.onlyDisabilityCommittedEmployer" }),
                    key: "onlyDisabilityCommittedEmployer",
                    value: this.props.criteria.onlyDisabilityCommittedEmployer,
                },
            ],
        };
    }

    render() {
        return (
            <div className="offers-filter offers-filter_contract">
                <h2 className="mobile-only filter-title">
                    <span className="icon-diploma" />
                    <FormattedMessage id="offers.filter.contract" />
                </h2>
                <div className="offers-filter-form offers-filter_contract-form">
                    <div className="select-offerType">
                        <PxdDropdownForm
                            placeholder={this.props.intl.formatMessage({ id: "search.advanced.location.contract.placeholder" })}
                            options={this.props.offerTypesReferential}
                            handleChange={this.props.handleChangeDropDown}
                            keyName={"offerTypes"}
                            ariaLabel={this.props.intl.formatMessage({ id: "search.advanced.location.contract.ariaLabel" })}
                            customClass="form-input-empty"
                        />
                    </div>
                </div>
                <div className="offers-filter-tags offers-filter_contract-tag">
                    <PxdTags
                        tags={this.props.tags.filter((e) => e.name !== this.props.intl.formatMessage({ id: "common.abroad" }))}
                        handleTagRemove={this.props.handleTagRemove}
                    />
                </div>
                <div className="offers-filter-form offers-filter_location-form foreign-switch-wrapper">
                    <PxdCheckboxForm
                        checkboxes={this.state.checkboxes}
                        customClass="checkbox-switch"
                        handleChange={this.props.handleCheckboxChange}
                    />
                </div>
            </div>
        );
    }
}

export default injectIntl(FilterContract);

