import React, { useEffect } from "react";

import GenericNotFound from "../../../../theme/pages/generic-not-found/generic-not-found";
import Footer from "../../../../theme/components/footer/footer";

function NotFound() {
    useEffect(() => {
        if (window.tarteaucitron.state.eulerian) {
            EA_push(["path", "404"]);
        }
    }, []);

    return (
        <div>
            <GenericNotFound withLogo withFooter withNavBar={false} />
            <Footer />
        </div>
    );
}

export default NotFound;
