class QueryParserUtils {
    static asArray(value) {
        let r = [];
        if (value !== undefined && value !== null && value !== "") {
            if (Array.isArray(value)) {
                r = value;
            } else {
                r = [value];
            }
        }
        return r;
    }
}

export default QueryParserUtils;
