import React from "react";
import "./pxd-tag.scss";

const PxdTag = props => {
    const closeMark = !props.close ? (
        <button type="button" className="btn-icon" onClick={e => props.handleRemove(e, props.keyName, props.value)}>
            <span className="icon-delete" />
        </button>
    ) : null;

    return (
        <div className="tag">
            {props.name}
            {closeMark}
        </div>
    );
};

export default PxdTag;
