import React, { useEffect, useRef, useState } from "react";
import "./filter-my-location.scss";
import { useTranslation } from "react-i18next";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import { PxdSwitch } from "../../../../../../theme";
import { OfferFiltersContext } from "../../offers-filter-context";
import PxdCustomSelect from "../../../../../../theme/components/pxd-custom-select/pxd-custom-select";
import ReferentialService from "../../../../../../config/services/referential.service";

function FilterMyLocation() {
    const { t } = useTranslation();

    const {
        regionsReferential,
        departmentsReferential,
        regions,
        setRegions,
        departments,
        setDepartments,
        cities,
        setCities,
        abroad,
        setAbroad,
        locationKeywords,
    } = React.useContext(OfferFiltersContext);

    const [autocompleteTowns, setAutocompleteTowns] = useState([]);
    const [textCities, setTextCities] = useState("");
    const debouncedGetCities = useRef();
    const [regionsData, setRegionsData] = useState([]);
    const [departmentsData, setDepartmentsData] = useState([]);

    const removeKeyword = (type, id) => {
        if (type === "region") {
            setRegions(regions.filter((bd) => bd !== id));
        }
        if (type === "department") {
            setDepartments(departments.filter((bd) => bd !== id));
        }
        if (type === "city") {
            setCities(cities.filter((bd) => bd !== id));
        }
    };

    const getCities = () => {
        if (textCities.length > 1) {
            if (debouncedGetCities.current) {
                clearTimeout(debouncedGetCities.current);
            }
            debouncedGetCities.current = setTimeout(() => {
                ReferentialService.getTowns(textCities).then((response) => {
                    setAutocompleteTowns(response.data);
                });
            }, 500);
        }
    };

    const generateDataFromReferential = (referential, setFunction) => () => {
        const newData = referential.map((l) => ({
            value: l.id,
            name: l.name,
        }));
        setFunction(newData);
    };

    const addCity = () => {
        if (textCities.length > 1) {
            if (!cities.find((elem) => elem === textCities)) {
                setCities([...cities, textCities]);
            }
        }
    };

    useEffect(generateDataFromReferential(regionsReferential, setRegionsData), [regionsReferential]);
    useEffect(generateDataFromReferential(departmentsReferential, setDepartmentsData), [departmentsReferential]);
    useEffect(getCities, [textCities]);

    const handleTextCitiesChange = (e) => {
        if (e?.target) {
            setTextCities(e.target.value);
        }
    };

    const handleSelectChange = (dataContainer, setDataContainer) => (e) => {
        if (e?.target?.value) {
            if (!dataContainer.find((elem) => elem === e.target.value)) {
                setDataContainer([...dataContainer, e.target.value]);
            }
        }
    };

    const generateOption = (data) => (
        <option className="searched-city" key={data.name} value={data.nameTown}>
            {data.name}
        </option>
    );

    const generateKeyword = (data) => (
        <li key={data.name} className="keyword-item">
            {data.name}
            <button type="button" className="remove-keyword-button" onClick={() => removeKeyword(data.type, data.id)}>
                <PxdIcon iconName="delete" />
            </button>
        </li>
    );

    return (
        <div className="my-job-location-wrapper">
            <h3 className="title">{t("offer-list.my-location")}</h3>
            <div className="location-filters">
                <label className="city-add-wrapper" htmlFor="city-add-input">
                    <input
                        type="text"
                        id="city-add-input"
                        className="city-add-input"
                        value={textCities}
                        onChange={handleTextCitiesChange}
                        list="towns"
                    />
                    <datalist id="towns">{autocompleteTowns.map(generateOption)}</datalist>
                    <button type="button" className="input-plus-button" onClick={addCity}>
                        <PxdIcon iconName="plus" />
                    </button>
                </label>
                <div className="department-select-wrapper">
                    <PxdCustomSelect
                        options={[
                            {
                                name: t("offer-list.choose-department"),
                                value: t("offer-list.choose-department"),
                            },
                            ...departmentsData,
                        ]}
                        onChange={handleSelectChange(departments, setDepartments)}
                    />
                </div>
                <div className="region-select-wrapper">
                    <PxdCustomSelect
                        options={[
                            {
                                name: t("offer-list.choose-region"),
                                value: t("offer-list.choose-region"),
                            },
                            ...regionsData,
                        ]}
                        onChange={handleSelectChange(regions, setRegions)}
                    />
                </div>
            </div>
            <div className="offer-abroad">
                <PxdSwitch activated={abroad} onClick={() => setAbroad(!abroad)} />
                {t("offer-list.filter-abroad")}
            </div>
            {locationKeywords && locationKeywords.length > 0 && (
                <div className="keywords-wrapper">
                    <h4 className="title">{t("offer-list.my-keywords")}</h4>
                    <ul className="keywords">{locationKeywords.map(generateKeyword)}</ul>
                </div>
            )}
        </div>
    );
}

export default FilterMyLocation;
