import React from "react";
import "./pxd-dropdown-form.scss";
import { injectIntl } from "react-intl";

class PxdDropDownForm extends React.Component {
    render() {
        return (
            <span className={`field-select ${this.props.isDisabled && "disabled"}`}>
                <select
                    className={`form-input ${this.props.hasError !== undefined && !this.props.hasError && "has-error"} ${
                        this.props.value === "" && "form-input-empty"
                    } ${this.props.customClass}`}
                    value={this.props.value || ""}
                    id={`${this.props.id || ""}`}
                    onChange={(e) => this.props.handleChange(e, this.props.keyName, this.props.referential)}
                    aria-label={this.props.ariaLabel}
                    aria-required={this.props.ariaRequired}
                    disabled={this.props.isDisabled}>
                    {this.props.placeholder && (
                        <option value="" disabled={!this.props.enablePlaceholder}>
                            {this.props.placeholder}
                        </option>
                    )}
                    {this.props.options?.map((option) => {
                        const { name, id } = option;
                        if (this.props.noIntl) {
                            return [
                                <option key={id} value={id}>
                                    {this.props.enum ? option.name : name}
                                </option>,
                            ];
                        }
                        return [
                            <option key={id} value={id}>
                                {this.props.enum ? this.props.intl.formatMessage({ id: option.name }) : name}
                            </option>,
                        ];
                    })}
                </select>
            </span>
        );
    }
}

export default injectIntl(PxdDropDownForm);
