import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/img/logo_ministere-des-armees.svg";
import PxdTabLink from "../../../app-shared/components/stateless/pxd-tab-link/pxd-tab-link";
import UserService from "../../../app-admin/users/services/user.service";
import { AuthContextHooks } from "../../../config/auth/auth.context.hooks";
import pkg from "../../../../package.json";
import "./pxd-navbar.scss";

function PxdNavBar(props) {
    const tabs = [
        {
            id: 1,
            title: "Liste des offres",
            icon: "diploma",
            url: "/offers",
            name: "offer",
            roles: ["ROLE_ADMIN", "ROLE_CENTRAL_CORRESPONDANT", "ROLE_LOCAL_CORRESPONDANT", "ROLE_LOCAL_CORRESPONDANT_REDACTEUR"],
        },
        {
            id: 2,
            title: "Logs",
            icon: "diploma",
            url: "/logs/",
            name: "logs",
            roles: ["ROLE_TECHNICAL_ADMIN"],
            externalUrl: true,
        },
        {
            id: 3,
            title: "Mon compte",
            icon: "people",
            url: "/profile",
            name: "profile",
            roles: [
                "ROLE_ADMIN",
                "ROLE_CENTRAL_CORRESPONDANT",
                "ROLE_LOCAL_CORRESPONDANT",
                "ROLE_TECHNICAL_ADMIN",
                "ROLE_LOCAL_CORRESPONDANT_REDACTEUR",
            ],
        },
        {
            id: 4,
            title: "Gérer les comptes",
            icon: "people-group",
            url: "/users",
            name: "user",
            roles: ["ROLE_ADMIN", "ROLE_CENTRAL_CORRESPONDANT", "ROLE_TECHNICAL_ADMIN"],
        },
        {
            id: 5,
            title: "Gestion des contenus",
            icon: "pen",
            url: "/content-management",
            name: "content-management",
            roles: ["ROLE_ADMIN", "ROLE_TECHNICAL_ADMIN"],
        },
    ];

    const { isAuthHooks, userHooks } = useContext(AuthContextHooks);

    return (
        <nav className={`mainNav ${isAuthHooks ? "connected" : "notConnected"}`}>
            <div className="logo">
                <img src={logo} alt="République Française" />
            </div>
            <div className="mainNav-menu">
                {isAuthHooks && userHooks && (
                    <div className="connected-user">{`${userHooks.firstName || ""} ${userHooks.lastName || ""}`}</div>
                )}
                {isAuthHooks &&
                    userHooks &&
                    userHooks.resetPasswordNeeded !== true &&
                    tabs.map((element) => UserService.hasRole(userHooks, element.roles) && <PxdTabLink tab={element} key={element.id} />)}
            </div>

            {isAuthHooks && (
                <Link to="/logout" className="logout">
                    <span className="icon-logout" />
                    <span className="libelle">Déconnexion</span>
                </Link>
            )}

            <div className="version">{pkg.version}</div>
        </nav>
    );
}

export default withRouter(PxdNavBar);
