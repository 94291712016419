import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./offers.scss";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import { OfferFiltersContext } from "../../offers-filter-context";
import slugify from "../../../../../../theme/utils/parse.helpers";
import OfferTypeEnum from "../../../../../../utils/enum/offer-type-enum";
import { getFirstDate, getLastDate } from "../../../../../../utils/functions/functions";

function Offers() {
    const { t } = useTranslation();
    const history = useHistory();
    const [offerList, setOfferList] = useState([]);
    const { offers } = React.useContext(OfferFiltersContext);
    const [searchUrl, setSearchUrl] = useState(null);

    useEffect(() => {
        setSearchUrl(`${history.location.search}`);
    }, [history]);

    useEffect(() => {
        setOfferList(offers);
    }, [offers]);

    const goToOffer = (data) => {
        const { title, offerId } = data;
        const url = `${slugify(title)}-${offerId}`;
        history.push(`/offer/${url}`, { searchUrl });
    };

    const generateOfferLine = (data) => (
        <li className="offer-line" key={data.offerId} onClick={() => goToOffer(data)}>
            <div className="offer-main-info">
                <h2 className="offer-main-title">
                    <span className="offer-title">{data.title}</span>
                    <span className="dash">-</span>
                    <span className="offer-direction">{data.direction.name}</span>
                </h2>
                <div className="date-data">
                    {data.offerType.nameOfferType !== OfferTypeEnum.junior_internship && (
                        <span className="duration">
                            {`${data.duration} `}
                            {t(`common.${data.durationType?.toLowerCase()}`)}
                        </span>
                    )}
                    {data.offerType.nameOfferType !== OfferTypeEnum.junior_internship && <span className="dash">-</span>}
                    {data.offerType.nameOfferType !== OfferTypeEnum.junior_internship && (
                        <span className="date-start">
                            {`${t("offer-list.to-be-filled")} `}
                            {data.offerMonth && t(`month.${data.offerMonth.toLowerCase()}`)}
                            {` ${data.offerYear}`}
                        </span>
                    )}

                    {data.offerType.nameOfferType === OfferTypeEnum.junior_internship && (
                        <span className="duration">
                            <FormattedMessage id="one-week" />
                        </span>
                    )}
                    {data.offerType.nameOfferType === OfferTypeEnum.junior_internship && <span className="dash">-</span>}
                    {data.offerType.nameOfferType === OfferTypeEnum.junior_internship && (
                        <span className="date-start">
                            {`${t("offer-list.to-be-filled-from")} `}
                            {data.weeksList && t(`${getFirstDate(data.weeksList)}`)}
                            {` ${t("offer-list.until")} `}
                            {data.weeksList && t(`${getLastDate(data.weeksList)}`)}
                        </span>
                    )}
                </div>
                <div className="location">{data.city.toUpperCase()}</div>
            </div>
            <div className="offer-type-wrapper">
                {data.offerType.nameOfferType === OfferTypeEnum.junior_internship && (
                    <div className="offer-type st3">{data.offerType.titleAbbrOfferType}</div>
                )}
                {data.offerType.nameOfferType === OfferTypeEnum.apprenticeship && (
                    <div className="offer-type app">{data.offerType.titleAbbrOfferType}</div>
                )}
                {data.offerType.nameOfferType === OfferTypeEnum.caj && (
                    <div className="offer-type caj">{data.offerType.titleAbbrOfferType}</div>
                )}
                {data.offerType.nameOfferType === OfferTypeEnum.internship && (
                    <div className="offer-type stg">{data.offerType.titleAbbrOfferType}</div>
                )}
            </div>
            <div className="go-to-offer-wrapper">
                <PxdIcon iconName="arrow-right" />
            </div>
        </li>
    );

    return (
        <div className="offers-list-wrapper">
            <ul className="offers-list">{offerList?.map(generateOfferLine)}</ul>
        </div>
    );
}

export default Offers;
