import React from "react";
import { FormattedMessage } from "react-intl";
import { v4 as uuid } from "uuid";

import "./pxd-tags.scss";
import PxdTag from "../../stateless/pxd-tag/pxd-tag";

class PxdTags extends React.Component {
    render() {
        return (
            this.props.tags &&
            this.props.tags.length > 0 && (
                <div className="tags-container">
                    <h3 className="tags-title">
                        <FormattedMessage id="search.advanced.keywords" />
                    </h3>

                    <div className="tags">
                        {this.props.tags.map(tag => (
                            <PxdTag
                                key={uuid()}
                                name={tag.name}
                                value={tag.value}
                                keyName={tag.keyName}
                                handleRemove={this.props.handleTagRemove}
                            />
                        ))}
                    </div>
                </div>
            )
        );
    }
}

export default PxdTags;
