import React from "react";
import man1 from "../../../../../../theme/assets/img/avatar_homme_1.svg";
import man2 from "../../../../../../theme/assets/img/avatar_homme_2.svg";
import woman1 from "../../../../../../theme/assets/img/avatar_femme_1.svg";
import woman2 from "../../../../../../theme/assets/img/avatar_femme_2.svg";

import evelynn from "../../../../../../theme/assets/img/avatar_evelynn.svg";
import louise from "../../../../../../theme/assets/img/avatar_louise.svg";
import maelle from "../../../../../../theme/assets/img/avatar_maelle.svg";
import mathieu from "../../../../../../theme/assets/img/avatar_mathieu.svg";
import max from "../../../../../../theme/assets/img/avatar_max.svg";
import olivier from "../../../../../../theme/assets/img/avatar_olivier.svg";
import "./testimony-card.scss";
import { AccessibilityContext } from "../../../../utils/accessibility-context";

const TestimonyCard = props => {
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);
    const avatarMap = { man1, man2, woman1, woman2, evelynn, louise, maelle, mathieu, max, olivier };
    const offerMap = {
        app: "Contrat apprentissage",
        stg: "Stage",
        caj: "Contrat armées-jeunesse",
        stgd: "Stage découverte"
    };

    const { testimonyData = {} } = props;
    const { avatar = "", firstname = "", age = "", organism = "", offer = "", content = "" } = testimonyData;

    return (
        <li className={`testimony-card ${accessibilityIsActivated ? "accessible" : ""}`}>
            <div className="testimony-card__avatar">{avatar && <img src={avatarMap[avatar]} alt="" />}</div>
            <div className="testimony-card__name">{`${firstname} - ${age} ans`}</div>
            <div className="testimony-card__organism">{organism}
                <div className="testimony-card__offer">{offer && offerMap[offer.toLowerCase()]}</div>
            </div>
            <p className="testimony-card__content">{content}</p>
        </li>
    );
};

export default TestimonyCard;
