import React from "react";
import "./pxd-checkbox-form.scss";

class PxdCheckboxForm extends React.Component {
    render() {
        return (
            <div className={`checkbox-container ${this.props.className ? this.props.className : ""}`}>
                {this.props.checkboxes.map((checkbox, index) => {
                    return (
                        <div key={checkbox.key} className={`${this.props.customClass}`}>
                            <input
                                type="checkbox"
                                id={checkbox.name}
                                value={checkbox.value}
                                defaultChecked={checkbox.value}
                                onChange={e => this.props.handleChange(e, checkbox.key, index)}
                            />
                            <label htmlFor={checkbox.name}></label>
                            <span>{checkbox.name}</span>
                        </div>
                    );
                })}
            </div>
        );
    }
}


export default PxdCheckboxForm;
