import EventEmitter from 'events';

const ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
    EVENT_NAME = 'notify';

class NotificationService extends EventEmitter {

    notify(message, level) {
        let notification = {
            message,
            level
        };
        this.emit(EVENT_NAME, notification);
    }

    error(message) {
        this.notify(message, ERROR);
    }

    warning(message) {
        this.notify(message, WARNING);
    }

    success(message) {
        this.notify(message, SUCCESS);
    }

    info(message) {
        this.notify(message, INFO);
    }

    addChangeListener(callback) {
        this.addListener(EVENT_NAME, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(EVENT_NAME, callback);
    }
}

export default new NotificationService();