import React from "react";
import logo from "../../assets/img/logo_ministere-des-armees.svg";
import "./public-logo.scss";

const PublicLogo = ({ className = "", fixed = false }) => {
    return (
        <div className={`public-logo-wrapper ${className} ${fixed ? "fixed" : ""}`}>
            <a href="https://www.defense.gouv.fr/" rel="noopener noreferrer" target="_blank">
                <img
                    src={logo}
                    className="public-logo"
                    title="République Française - Ministère des armées"
                    alt="République Française - Ministère des armées"
                />
            </a>
        </div>
    );
};

export default PublicLogo;
