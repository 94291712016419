import React, { createContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import UserService from "../../app-admin/users/services/user.service";

const AuthContextHooks = createContext({});

const AuthProviderHooks = withRouter(props => {
    const { children } = props;

    const [isAuthHooks, setIsAuthHooks] = useState(false);
    const [userHooks, setUserHooks] = useState(undefined);
    const [isLoadedHooks, setIsLoadedHooks] = useState(false);

    const loginHooks = user => {
        if (user) {
            setUserHooks(user);
            setIsAuthHooks(true);
            setIsLoadedHooks(true);
        }
    };

    const logoutHooks = () => {
        setUserHooks(undefined);
        setIsAuthHooks(false);
        setIsLoadedHooks(true);
    };

    useEffect(() => {
        UserService.getCurrent()
            .then(rsp => {
                loginHooks(rsp.data);
            })
            .catch(() => {
                setIsLoadedHooks(true);
            });
    }, []);

    return (
        <AuthContextHooks.Provider value={{ isAuthHooks, isLoadedHooks, userHooks, loginHooks, logoutHooks }}>
            {children}
        </AuthContextHooks.Provider>
    );
});

const AuthConsumerHooks = AuthContextHooks.Consumer;

export { AuthContextHooks, AuthProviderHooks, AuthConsumerHooks };
