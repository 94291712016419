const gregorian_fr = {
    name: "gregorian_fr",
    months: [
        ["Janvier", "janv"],
        ["Février", "févr"],
        ["Mars", "mars"],
        ["Avril", "avr"],
        ["Mai", "mai"],
        ["Juin", "juin"],
        ["Juillet", "juil"],
        ["Août", "août"],
        ["Septembre", "sept"],
        ["Octobre", "oct"],
        ["Novembre", "nov"],
        ["Décembre", "déc"],
    ],
    weekDays: [
        ["Samedi", "sa"],
        ["Dimanche", "di"],
        ["Lundi", "lu"],
        ["Mardi", "ma"],
        ["Mercredi", "me"],
        ["Jeudi", "je"],
        ["Vendredi", "ve"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
};
export default gregorian_fr;