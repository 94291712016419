import React from "react";
import { Link } from "react-router-dom";
import PublicLogo from "../../../../../../theme/components/public-logo/public-logo";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import OfferListFilters from "../offer-list-filters/offer-list-filters";
import { AccessibilityContext } from "../../../../utils/accessibility-context";
import "./offer-list-header.scss";

function OfferListHeader(props) {
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);

    const { filters = false, pageName, title, subtitle, links, disabilityCommittedEmployer } = props;

    return (
        <header className={`offer-list-header ${accessibilityIsActivated ? "accessible" : ""}`}>
            <PublicLogo />
            <div className="header-content">
                <div className="breadcrumbs-wrapper">
                    <Link
                        to="/"
                        className={`home-button breadcrumbs-item ${links && links.length > 0 ? "hide-mobile" : "breadcrumbs-main"}`}
                        title="Retour à la page d'accueil">
                        <PxdIcon className="home-icon" iconName="home" />
                        <div className="home-text">{"Retour à la page d'accueil"}</div>
                    </Link>
                    {links?.map((link) => {
                        const { name, goToLink } = link;
                        return (
                            <>
                                <span className="breadcrumbs-separator">/</span>
                                <button onClick={goToLink} type="button" className="breadcrumbs-item breadcrumbs-main">
                                    {name}
                                </button>
                            </>
                        );
                    })}
                    <span className="breadcrumbs-separator">/</span>
                    <span className="breadcrumbs-item active hide-mobile">{pageName}</span>
                </div>
                {filters && <OfferListFilters className="ol-filters" />}
                <div className="header-bottom-line">
                    <div className="header-left">
                        {title && <h1 className={`offer-list-header__title ${filters ? "hide-desktop" : ""}`}>{title}</h1>}
                        {subtitle && <h2 className="offer-list-header__subtitle">{subtitle}</h2>}
                    </div>
                    <div className="header-right">
                        <span className={`disability-committed-employer ${disabilityCommittedEmployer ? "" : "hide-desktop"}`}>
                            Employeur Handi-Engagé
                        </span>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default OfferListHeader;
