import qs from "query-string";
import api from "../../../app-shared/components/utils/http/http-service";
import OfferStatusIds from "../../../app-shared/components/utils/constants/offer-status-id";

class OfferService {
    static createOrUpdateOffer(offer) {
        if (!offer.accommodation) {
            offer.accommodation = null;
        }
        if (!offer.wage && offer.wage !== false) {
            offer.wage = null;
        }
        if (!offer.food) {
            offer.food = null;
        }
        return api.put("/admin/offers", offer);
    }

    static assignOffer(user, criteria, sort) {
        const query = qs.stringify({ ...criteria, ...sort });
        return api.put(`/admin/offers/assign?${query}`, user);
    }

    static deleteOffer(offerId) {
        return api.delete(`/admin/offers/${offerId}`);
    }

    static getOffer(offerId) {
        return api.get(`/admin/offers/${offerId}`);
    }

    static getOffers(criteria, sort, range) {
        const query = qs.stringify({ ...criteria, ...sort, ...range });
        return api.get(`/admin/offers?${query}`);
    }

    static getOffersCount(criteria) {
        const query = qs.stringify({ ...criteria });
        return api.get(`/admin/offers/count?${query}`);
    }

    static countOffersNotClosed(criteriaState) {
        const criteria = { ...criteriaState };
        if (criteria.offerStatus.length === 0) {
            criteria.offerStatus = [OfferStatusIds.DRAFT, OfferStatusIds.PUBLISH, OfferStatusIds.SUSPENDED];
        } else {
            criteria.offerStatus = criteria.offerStatus.filter((element) => element !== OfferStatusIds.CLOSED);
        }
        const query = qs.stringify({ ...criteria });

        return api.get(`/admin/offers/count?${query}`);
    }

    static generatePdfOffer(criteriaState) {
        const criteria = { ...criteriaState };
        criteria.offerStatus = [OfferStatusIds.PUBLISH];
        const query = qs.stringify({ ...criteria });
        return api.get(`/admin/offers/generate?${query}`, {
            responseType: "blob",
        });
    }

    static getOfferStatus(offerId) {
        return api.get(`/admin/offers/${offerId}/status`);
    }
}

export default OfferService;
