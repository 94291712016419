import React from "react";
import SymbolsDef from "../../assets/icons/symbol-defs.svg";
import "./pxd-icon.scss";

const PxdIcon = props => {
    const { iconName = "", className = "" } = props;
    return (
        <svg viewBox="0 0 16 16" className={`search-icon icon-${iconName} ${className}`}>
            <use xlinkHref={`${SymbolsDef}#icon-${iconName}`} />
        </svg>
    );
};

export default PxdIcon;
