import React from "react";
import "./more-results.scss";
import { useTranslation } from "react-i18next";
import { OfferFiltersContext } from "../../offers-filter-context";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";

function OffersMoreResults() {
    const { t } = useTranslation();

    const { setOfferSize, offerSize, pageSize } = React.useContext(OfferFiltersContext);

    return (
        <div className="more-results-wrapper">
            <button type="button" className="more-result-button" onClick={() => setOfferSize(offerSize + pageSize)}>
                <PxdIcon iconName="plus" />
                {t("common.pagination.more-results")}
            </button>
        </div>
    );
}

export default OffersMoreResults;
