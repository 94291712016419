import React from "react";

import Notifications from "../app-shared/components/stateful/notification/notifications";
import SwitchRouter from "./components/utils/switch-router/switch-router";
import { AccessibilityProvider } from "./components/utils/accessibility-context";
import { OfferFiltersProvider } from "./components/page/offer-list/offers-filter-context";

const Main = () => {
    return (
        <AccessibilityProvider>
            <OfferFiltersProvider>
                <Notifications />
                <SwitchRouter />
            </OfferFiltersProvider>
        </AccessibilityProvider>
    );
};

export default Main;
