import React from "react";
import "./generic-not-found.scss";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PublicLogo from "../../components/public-logo/public-logo";

const GenericNotFound = ({ withLogo = false, withFooter = false, withNavBar = true }) => {
    return (
        <div className={`notFound ${withFooter ? "withFooter" : ""} ${withNavBar ? "withNavBar" : ""}`}>
            <header className="notFound_header">{withLogo && <PublicLogo />}</header>
            <section className="wrapper">
                <div className="notFound_content">
                    <div className="title">
                        <FormattedMessage id="page.not-found.title" />
                    </div>
                    <p>
                        <FormattedMessage id="page.not-found.text" />
                    </p>
                </div>
                <Link className="btn-rounded" to="/">
                    <FormattedMessage id="page.not-found.button" />
                </Link>
            </section>
        </div>
    );
};

export default GenericNotFound;
