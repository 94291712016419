function getFirstDate(datePickerValues) {
    const array = JSON.parse(datePickerValues);
    if (array !== undefined) {
        if (array[0] !== undefined) {
            return array[0][0];
        }
    }
    return null;
}

function getLastDate(datePickerValues) {
    const array = JSON.parse(datePickerValues);
    if (array !== undefined) {
        if (array[array.length - 1] !== undefined) {
            return array[array.length - 1][array[array.length - 1].length - 1];
        }
    }
    return null;
}

export { getFirstDate, getLastDate };
