import React, { useState } from "react";
import OfferCard from "../offer-card/offer-card";
import "./offer-card-list.scss";
import Modal from "../../../../../../theme/components/modal/modal";

const body = document.querySelector("body");
const OfferCardList = props => {
    const { contents = [] } = props;

    const [modalOpened, setModalOpened] = useState(false);
    const [modalData, setModalData] = useState({});

    const openModal = data => {
        body.style.overflow = "hidden";
        setModalOpened(true);
        setModalData(data);
    };

    const hide = () => {
        setModalOpened(false);
        body.style.overflow = "auto";
    };

    const cardsConfig = [
        {
            title: contents["block1.title"],
            abbr: contents["block1.abbr"],
            longtext: contents["block1.longtext"],
            abbrColor: "#f5a623",
            abbrColorDark: "#eb970a",
            infos: [contents["block1.bullet1"], contents["block1.bullet2"], contents["block1.bullet3"]]
        },
        {
            title: contents["block3.title"],
            abbr: contents["block3.abbr"],
            longtext: contents["block3.longtext"],
            abbrColor: "#6aa3dc",
            abbrColorDark: "#4a8fd4",
            infos: [contents["block3.bullet1"], contents["block3.bullet2"], contents["block3.bullet3"]]
        },
        {
            title: contents["block2.title"],
            abbr: contents["block2.abbr"],
            longtext: contents["block2.longtext"],
            abbrColor: "#8CB369",
            abbrColorDark: "#7aa554",
            infos: [contents["block2.bullet1"], contents["block2.bullet2"], contents["block2.bullet3"]]
        }
    ];

    const generateOfferCard = cardData => {
        return (
            <React.Fragment key={cardData.title + cardData.abbr}>
                {modalOpened && <Modal show={modalOpened} data={modalData} hide={hide} />}
                <OfferCard
                    title={cardData.title}
                    abbr={cardData.abbr}
                    abbrColor={cardData.abbrColor}
                    abbrColorDark={cardData.abbrColorDark}
                    infoList={cardData.infos}
                    longtext={cardData.longtext}
                    openModal={openModal}
                />
            </React.Fragment>
        );
    };

    return <ul className="offer-card-list">{cardsConfig.map(generateOfferCard)}</ul>;
};

export default OfferCardList;
