import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { AuthContextHooks } from "../../../../config/auth/auth.context.hooks";

const PxdTabLink = props => {
    const { isAuthHooks } = useContext(AuthContextHooks);
    const icon = props.tab.icon ? <span className={`icon-${props.tab.icon}`} /> : null;
    let tab;

    if (props.tab.externalUrl) {
        tab = isAuthHooks && (
            <a href={props.tab.url} target={"_blank"} rel="noopener noreferrer" className={"tab"}>
                {icon}
                <span className="libelle">{props.tab.title}</span>
            </a>
        );
    } else {
        tab = isAuthHooks && (
            <Link to={props.tab.url} className={`tab ${props.location.pathname.includes(props.tab.name) ? "selected" : ""}`}>
                {icon}
                <span className="libelle">{props.tab.title}</span>
            </Link>
        );
    }

    return tab;
};
export default withRouter(PxdTabLink);
