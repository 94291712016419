import React from "react";
import "./pxd-title.scss";

const PxdTitle = props => {
    const { text = "", className = "" } = props;
    let { level = 1 } = props;

    if (isNaN(level)) {
        try {
            level = parseInt(level, 10);
        } catch (err) {
            console.error(err);
            // default to 1 if not castable
            level = 1;
        }
    }

    if (level > 6) {
        level = 6;
    }

    return React.createElement(`h${level}`, { className: `pxd-title pxd-title--${level} ${className}` }, text);
};

export default PxdTitle;
