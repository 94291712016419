import React from 'react';

class PxdLoader extends React.Component {

    render() {
        return (
        /*     Si visible : .show sinon .hide

                    Si couvre toute la page : .full
                    Si couvre uniquement le contenu principal : .in-content et mettre le contenu de page en display none
                    Si validation modal (cf dans le composant modal) : .action-in-progress
                */
            <div className={`spinner ${this.props.showLoader ? "show" : "hide"} ${this.props.customClass}`}>
                <span className="bounce1"/>
                <span className="bounce2"/>
                <span className="bounce3"/>
            </div>
        )
    }
}

export default PxdLoader;