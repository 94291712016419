import React from "react";
import { PxdTitle } from "../../index";
import "./modal.scss";
import { AccessibilityContext } from "../../../app-frontal/components/utils/accessibility-context";

const Modal = props => {
    const { data = {}, hide = () => {} } = props;
    const { title = "", tag = "", text = "" } = data;
    const { isAccessibilityEnabled } = React.useContext(AccessibilityContext);

    const close = () => {
        hide();
    };

    return (
        <div className="screen-wrapper">
            <div className="pxd-modal-wrapper ">
                <div className="pxd-modal ">
                    <button className="close-button-wrapper" type="button" onClick={close}>
                        <span className="icon-delete" />
                    </button>
                    <PxdTitle level={2} text={title} className="popup-title" />
                    <div className="tag-position">
                        {tag && <div className={`offer-tag ${tag.toLowerCase()} ${isAccessibilityEnabled ? "accessible" : ""}`}>{tag}</div>}
                        {tag && tag === "STG" && <div className={`offer-tag st3 ${isAccessibilityEnabled ? "accessible" : ""}`}>ST3</div>}
                    </div>
                    <p className="offer-text">
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Modal;
