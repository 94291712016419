import React from "react";
import { AccessibilityContext } from "../../../../app-frontal/components/utils/accessibility-context";
import "./pxd-card.scss";

const PxdCard = props => {
    const { infoCard, title } = props;
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);

    const info = infoCard.map(el => {
        if (el.value && el.value.length > 0) {
            return [
                <li key={el.key}>
                    <span className="info">{`${el.key} : `}</span>
                    {el.value}
                </li>
            ];
        }
        return "";
    });

    return (
        <div className={`pxd-card ${accessibilityIsActivated ? "accessible" : ""}`}>
            <h2 className="pxd-card__title">{title}</h2>
            <ul className="pxd-card__list">{info}</ul>
        </div>
    );
};

export default PxdCard;
