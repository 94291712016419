import React from "react";
import "./offer-card.scss";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import { AccessibilityContext } from "../../../../utils/accessibility-context";

const OfferCard = props => {
    const {
        infoList = [],
        title = "",
        abbr = "",
        longtext = "",
        abbrColor = "#f5a623",
        abbrColorDark = "#eb970a",
        openModal = () => {}
    } = props;
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);

    const openModalLongText = () => {
        openModal({
            title,
            tag: abbr,
            text: longtext
        });
    };

    const generateInfoItem = infoList.map(info => {
        return [
            <li key={info} className="offer-card__list__info">
                {info}
            </li>
        ];
    });

    return (
        <div className="offer-card">
            <h2 className="offer-card__title">{title}</h2>
            <div className="offer-card__wrapper">
                <div className="offer-card-abbr__wrapper">
                    {abbr !== "STG" && (
                        <h3
                            className="offer-card__abbr offer-card-single"
                            style={{ background: accessibilityIsActivated ? abbrColorDark : abbrColor }}>
                            {abbr}
                        </h3>
                    )}
                    {abbr === "STG" && (
                        <h3
                            className="offer-card__abbr offer-card-double-left"
                            style={{ background: accessibilityIsActivated ? abbrColorDark : abbrColor }}>
                            {abbr}
                        </h3>
                    )}
                    {abbr === "STG" && <h3 className="offer-card__abbr offer-card-double-right">ST3</h3>}
                </div>
                <ul className="offer-card__list">{generateInfoItem}</ul>
                <button className="offer-card__more" onClick={openModalLongText} type="button">
                    <PxdIcon iconName="plus" />
                    <span>En savoir Plus</span>
                </button>
            </div>
        </div>
    );
};

export default OfferCard;
