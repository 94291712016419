import React from "react";
import "./pxd-input-form.scss";

class PxdInputForm extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.state = {
            type: this.props.type,
            showAutocomplete: false,
            cursor: -1,
            show: false,
        };
        this._nodes = new Map();
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChangeAutoComplete = this.handleChangeAutoComplete.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleClickRevealPassword = this.handleClickRevealPassword.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ showAutocomplete: false });
        }
    }

    componentWillUpdate(nextProps) {
        if (JSON.stringify(nextProps.autocompleteOptions) !== JSON.stringify(this.props.autocompleteOptions)) {
            this.setState({ cursor: -1 });
        }
    }

    handleChange(e) {
        if (this.props.handleChange) {
            this.props.handleChange(e, this.props.keyName);
            this.setState({ showAutocomplete: true });
        }
    }

    handleChangeAutoComplete(e, option) {
        if (this.props.handleChangeAutoComplete) {
            this.props.handleChangeAutoComplete(e, this.props.keyName, option);
            this.setState({ showAutocomplete: false });
        }
    }

    handleKeyPress(e) {
        if (e.which === 13 || e.key === "Enter" || e.charCode === 13) {
            if (this.props.handleEnterKeyPress && e.target.value && e.target.value !== "" && this.state.cursor === -1) {
                this.props.handleEnterKeyPress(e, this.props.keyName);
                e.target.value = "";
                this.setState({ showAutocomplete: false });
            } else if (this.props.handleChangeAutoComplete && this.state.cursor !== -1) {
                const option = this.props.autocompleteOptions.find((option, i) => i === this.state.cursor);
                e.target.value = option;
                this.handleChangeAutoComplete(e, option);
            }
        }
    }

    handleKeyDown(e) {
        const { cursor } = this.state;
        // arrow up/down button should select next/previous list element
        if (e.keyCode === 38 && cursor > -1) {
            this.setState((prevState) => ({
                cursor: prevState.cursor - 1,
            }));
        } else if (e.keyCode === 40 && this.props.autocompleteOptions && cursor < this.props.autocompleteOptions.length - 1) {
            this.setState((prevState) => ({
                cursor: prevState.cursor + 1,
            }));
        } else if ((e.keyCode === 8 || e.keyCode === 46) && this.props.handleDeleteChange) {
            this.props.handleDeleteChange();
        }
        this.handleShow(e, cursor);
    }

    handleClickRevealPassword() {
        let { type } = this.state;
        if (type === "password") {
            type = "text";
        } else {
            type = "password";
        }
        this.setState({ type });
    }

    handleButtonClick() {
        this.props.handleButtonClick(this.inputRef, this.props.keyName);
        this.inputRef.current.value = "";
    }

    handleShow(e, i) {
        this.setState({ index: i });
        const node = this._nodes.get(i);
        if (node) {
            node.scrollIntoView({ block: "nearest", behavior: "smooth" });
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {
        return (
            <div
                ref={this.setWrapperRef}
                className={`field-input ${this.props.className} ${
                    this.props.buttonAdd !== undefined || this.props.type === "password" ? "with-button" : ""
                }
            ${this.props.autocompleteOptions ? "field-autocomplete" : ""}`}
                onFocus={() => this.setState({ showAutocomplete: true })}>
                <input
                    type={this.state.type}
                    id={`${this.props.id || ""}`}
                    min={this.props.numberMin}
                    ref={this.inputRef}
                    className={`form-input ${this.props.customClass} ${
                        this.props.hasError !== undefined && !this.props.hasError ? "has-error" : ""
                    }`}
                    value={this.props.value}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    placeholder={this.props.placeholder}
                    onKeyDown={this.handleKeyDown}
                    disabled={this.props.isDisabled}
                    aria-label={this.props.ariaLabel}
                    aria-required={this.props.ariaRequired}
                    onFocus={() => this.setState({ showAutocomplete: true })}
                />
                {this.props.buttonAdd && (
                    <button type="button" className="btn-rounded" onClick={this.handleButtonClick} title="Ajouter le filtre">
                        <span className="icon-plus" />
                    </button>
                )}
                {this.props.type === "password" && this.props.value !== "" && (
                    <span
                        onMouseDown={() => this.handleClickRevealPassword()}
                        onMouseUp={() => this.handleClickRevealPassword()}
                        className={"icon-eye"}
                    />
                )}

                {/* Pour auto-complete */}
                {this.state.showAutocomplete && this.props.autocompleteOptions?.length > 0 && (
                    <ul className="field-autocomplete_list nostyle">
                        {this.props.autocompleteOptions.map((option, index) => {
                            const classNames = option.isParent ? "nostyle parent" : "nostyle child";
                            return (
                                <li
                                    ref={(element) => this._nodes.set(index, element)}
                                    key={option.id}
                                    className={`${this.state.cursor === index ? "active" : ""}`}>
                                    <button
                                        type="button"
                                        className={classNames}
                                        value={option}
                                        onClick={(e) => this.handleChangeAutoComplete(e, option)}>
                                        <span className={`icon-${this.props.icon}`} />
                                        {option.name}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }
}

PxdInputForm.defaultProps = {
    type: "text",
};

export default PxdInputForm;
