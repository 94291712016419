import React, { useEffect, useState, useRef } from "react";
import "./application-form.scss";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import PxdInputForm from "../../../../app-shared/components/stateful/pxd-form/pxd-input-form/pxd-input-form";
import OfferListHeader from "../offer-list/internal-components/offer-list-header/offer-list-header";
import ScrollToTop from "../../../../theme/components/scroll-to-top/scroll-to-top";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PxdButton from "../../../../app-shared/components/stateless/pxd-button/pxd-button";
import Footer from "../../../../theme/components/footer/footer";
import checkObjectValid from "../../../../theme/utils/validation/functional-validator-service";
import NotificationService from "../../../../app-shared/components/stateful/notification/notification.service";
import ApplicationService from "../../../services/application.service";
import OfferService from "../../../../config/services/offer.service";
import slugify from "../../../../theme/utils/parse.helpers";
import PxdApplicationFormUpload from "../../../../app-shared/components/stateful/pxd-application-form/pxd-application-form-upload/pxd-application-form-upload";
import PxdApplicationFormDelete from "../../../../app-shared/components/stateful/pxd-application-form/pxd-application-form-delete/pxd-application-form-delete";
import { codePattern, linkPattern } from "../../../../utils/constants/regex";

function ApplicationForm(props) {
    const MAX_APPLICATION_LENGTH = 5000;

    const {
        match: { params },
    } = props;

    const { offerUrl } = params;

    const location = useLocation();
    const { t } = useTranslation();
    const history = useHistory();
    const [offer, setOffer] = useState(null);
    const [searchUrl, setSearchUrl] = useState(null);
    const fileInputRefResume = useRef(null);
    const fileInputRefCoverLetter = useRef(null);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromText(
                "Madame, Monsieur,\n" +
                    "Je vous transmets, ci-joint, mon curriculum vitae et ma lettre de motivation pour l'offre ci-référencée.\n" +
                    "Cordialement,",
            ),
        ),
    );
    const [application, setApplication] = useState({
        offerReference: null,
        offerContactEmail: null,
        lastName: null,
        firstName: null,
        applicationContent: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        phoneNumber: null,
        email: null,
        resumeFile: null,
        coverLetterFile: null,
        resumeFileName: null,
        coverLetterFileName: null,
    });

    const [validators] = useState({
        lastName: { required: true, maxLength: 50, regex: "name" },
        firstName: { required: true, maxLength: 50, regex: "name" },
        applicationContent: { required: true, maxLength: MAX_APPLICATION_LENGTH },
        phoneNumber: { required: true, regex: "phoneNumber" },
        email: { required: true, regex: "email", maxLength: 320 },
        resumeFile: { required: true },
        coverLetterFile: { required: true },
    });

    // charCount's intial state = length of the editor state's default text
    const [charCount, setCharCount] = useState(136);
    const [hasCode, setHasCode] = useState(false);
    const [hasLinks, setHasLinks] = useState(false);

    const getOfferId = (url) => {
        const urlArr = url.split("-");
        if (urlArr.length > 0) return urlArr[urlArr.length - 1];
        return undefined;
    };

    const [envoiEnCours, setEnvoiEnCours] = useState(false);

    useEffect(() => {
        if (location.state?.offer !== undefined) {
            setOffer(location.state.offer);
            setSearchUrl(location.state.searchUrl);
            const newApplication = application;
            application.offerReference = location.state.offer.reference;
            application.offerContactEmail = location.state.offer.contactEmail;
            setApplication(newApplication);
        } else {
            setSearchUrl(null);
            const offerId = getOfferId(offerUrl);
            OfferService.getOffer(offerId, false)
                .then((response) => {
                    setOffer(response.data);
                    const newApplication = application;
                    application.offerReference = response.data.reference;
                    application.offerContactEmail = response.data.contactEmail;
                    setApplication(newApplication);
                })
                .catch((err) => {
                    console.error(err);
                    history.push("/404");
                });
        }
        // initiate isValid to true with default text
        checkObjectValid({ applicationContent: application.applicationContent }, validators);
    }, [location.state]);

    useEffect(() => {
        if (location.state?.searchUrl) {
            setSearchUrl(location.state.searchUrl);
        }
    }, []);

    useEffect(() => {
        const plainText = editorState.getCurrentContent().getPlainText("");
        setHasLinks(linkPattern.test(plainText));
        setHasCode(codePattern.test(plainText));

        // Calculate character count
        setCharCount(plainText.length);
    }, [editorState]);

    const goToOfferPage = () => {
        const url = `${slugify(offer.title)}-${offer.offerId}`;
        history.push({
            pathname: `/offer/${url}/`,
        });
    };

    const goToResearchPage = () => {
        if (searchUrl !== null) {
            history.push(history.location.state.searchUrl);
            history.push({
                pathname: "/offers",
                search: searchUrl,
            });
        } else {
            history.push("/offers");
        }
    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        setApplication({ ...application, applicationContent: draftToHtml(convertToRaw(editorState.getCurrentContent())) });
    };

    const isApplicationValid = () => {
        const rules = checkObjectValid(application, validators, true);
        return !!rules.allValid && editorState.getCurrentContent().getPlainText() !== "";
    };

    const strToBool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") {
                return true;
            }
            if (value.toLowerCase() === "false") {
                return false;
            }
        }
        return value;
    };

    const handleChange = (e, key) => {
        const newApplication = { ...application };
        newApplication[key] = strToBool(e.target.value);
        checkObjectValid({ [key]: newApplication[key] }, validators);
        setApplication(newApplication);
    };

    const submitApplication = () => {
        ApplicationService.submitApplication(application)
            .then((rsp) => {
                if (rsp.data === "OK") {
                    if (window.tarteaucitron.state.eulerian) {
                        EA_push(["path", `candidature/${offer.offerType.titleAbbrOfferType}`]);
                    }

                    NotificationService.success(t("application-form.submission-status.success"));
                    goToOfferPage();
                } else {
                    NotificationService.error(t("application-form.submission-status.error"));
                }
            })
            .catch(() => {
                // application has not been sent successfully (internal error)
                NotificationService.error(t("notification.internal.error"));
            })
            .finally(() => {
                // Rétablir l'état pour permettre de nouveaux envois
                setEnvoiEnCours(false);
            });
    };

    const handleSubmit = () => {
        if (envoiEnCours) {
            return;
        }
        setEnvoiEnCours(true);
        const baseDelay = 1000;
        let multiplier;
        let mainValidation = true;
        let codeValidation = true;
        let linkValidation = true;

        if (!isApplicationValid()) {
            mainValidation = false;
        }
        if (hasCode) {
            codeValidation = false;
        }
        if (hasLinks) {
            linkValidation = false;
        }
        if (!mainValidation) {
            NotificationService.error(t("application-form.form-status.error.fields"));
        }

        multiplier = 0;
        if (!codeValidation) {
            if (!mainValidation) {
                multiplier += 1;
            }
            setTimeout(() => {
                NotificationService.error(t("application-form.form-status.error.code"));
            }, baseDelay * multiplier);
        }

        multiplier = 0;
        if (!linkValidation) {
            if (!codeValidation) {
                multiplier += 1;
            }
            if (!mainValidation) {
                multiplier += 1;
            }
            setTimeout(() => {
                NotificationService.error(t("application-form.form-status.error.links"));
            }, baseDelay * multiplier);
        }

        if (mainValidation && linkValidation && codeValidation) {
            submitApplication();
        } else {
            setEnvoiEnCours(false);
        }
    };

    const updateApplication = (newApplication) => {
        if (newApplication) {
            setApplication(newApplication);
        }
    };

    return (
        <div>
            {offer && (
                <div>
                    <ScrollToTop />
                    <OfferListHeader
                        links={[
                            { name: t("offer-list.your-search"), goToLink: goToResearchPage },
                            { name: t("offer-front.offer"), goToLink: goToOfferPage },
                        ]}
                        pageName={t("Candidater")}
                        title={`Candidature à l'offre ${offer.reference}`}
                        subtitle={offer.direction && <span className="entity">{offer.direction.name}</span>}
                    />
                    <div className="wrapper-admin">
                        <form
                            className="form-container"
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}>
                            <fieldset>
                                <div className="line-field">
                                    <label htmlFor="lastName">
                                        <FormattedMessage id="offer.application.lastname" />
                                        <span className="mandatory">*</span>
                                    </label>
                                    <div>
                                        <PxdInputForm
                                            id="lastName"
                                            placeholder="Nom"
                                            handleChange={handleChange}
                                            keyName="lastName"
                                            ariaRequired
                                            hasError={validators.lastName.isValid}
                                        />
                                    </div>
                                </div>
                                <div className="line-field">
                                    <label htmlFor="firstName">
                                        <FormattedMessage id="offer.application.firstname" />
                                        <span className="mandatory">*</span>
                                    </label>
                                    <div>
                                        <PxdInputForm
                                            id="firstName"
                                            placeholder="Prénom"
                                            handleChange={handleChange}
                                            keyName="firstName"
                                            ariaRequired
                                            hasError={validators.firstName.isValid}
                                        />
                                    </div>
                                </div>
                                <div className="line-field">
                                    <label htmlFor="applicationContent">
                                        <FormattedMessage id="offer.application.content" />
                                        <span className="mandatory">*</span>
                                    </label>
                                    <div className="content">
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName={`editorClassName ${hasCode || hasLinks ? "has-code-or-links" : ""}`}
                                            onEditorStateChange={onEditorStateChange}
                                            toolbar={{
                                                options: ["inline", "blockType", "list"],
                                                inline: {
                                                    options: ["bold", "superscript"],
                                                },
                                                blockType: {
                                                    options: ["Normal", "H1", "H2"],
                                                },
                                            }}
                                        />
                                        <div className={`character-count ${charCount > MAX_APPLICATION_LENGTH ? "exceeded-limit" : ""}`}>
                                            {charCount}/{MAX_APPLICATION_LENGTH}
                                        </div>
                                    </div>
                                </div>
                                <div className="line-field">
                                    <label htmlFor="phoneNumber">
                                        <FormattedMessage id="offer.application.phone" />
                                        <span className="mandatory">*</span>
                                    </label>
                                    <div>
                                        <PxdInputForm
                                            id="phoneNumber"
                                            type="text"
                                            handleChange={handleChange}
                                            keyName="phoneNumber"
                                            placeholder="0123456789"
                                            hasError={validators.phoneNumber.isValid}
                                            ariaRequired
                                        />
                                    </div>
                                </div>
                                <div className="line-field">
                                    <label htmlFor="email">
                                        <FormattedMessage id="offer.application.email" />
                                        <span className="mandatory">*</span>
                                    </label>
                                    <div>
                                        <PxdInputForm
                                            id="email"
                                            type="email"
                                            value={application.email || ""}
                                            handleChange={handleChange}
                                            keyName="email"
                                            placeholder="exemple@mail.fr"
                                            hasError={validators.email.isValid}
                                            ariaRequired
                                        />
                                    </div>
                                </div>

                                <div className="line-field">
                                    <label htmlFor="entity">
                                        <FormattedMessage id="offer.application.resume" />
                                        <span className="mandatory">*</span>
                                    </label>
                                    <div className="file-input-container">
                                        <PxdApplicationFormUpload
                                            file={"resume"}
                                            fileInputRef={fileInputRefResume}
                                            application={application}
                                            updateApplication={updateApplication}
                                        />
                                        {application.resumeFile && <p>{application.resumeFileName}</p>}
                                        {application.resumeFile && (
                                            <PxdApplicationFormDelete
                                                file={"resume"}
                                                fileInputRef={fileInputRefResume}
                                                application={application}
                                                updateApplication={updateApplication}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="line-field-2">
                                    <label htmlFor="entity">
                                        <FormattedMessage id="offer.application.allowed.formats" />
                                    </label>
                                    <label htmlFor="entity">
                                        <FormattedMessage id="offer.application.allowed.file-size" />
                                    </label>
                                </div>

                                <div className="line-field">
                                    <label htmlFor="entity">
                                        <FormattedMessage id="offer.application.cover-letter" />
                                        <span className="mandatory">*</span>
                                    </label>
                                    <div className="file-input-container">
                                        <PxdApplicationFormUpload
                                            file={"cover-letter"}
                                            fileInputRef={fileInputRefCoverLetter}
                                            application={application}
                                            updateApplication={updateApplication}
                                        />
                                        {application.coverLetterFile && <p>{application.coverLetterFileName}</p>}
                                        {application.coverLetterFile && (
                                            <PxdApplicationFormDelete
                                                file={"cover-letter"}
                                                fileInputRef={fileInputRefCoverLetter}
                                                application={application}
                                                updateApplication={updateApplication}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="line-field-2">
                                    <label htmlFor="entity">
                                        <FormattedMessage id="offer.application.allowed.formats" />
                                    </label>
                                    <label htmlFor="entity">
                                        <FormattedMessage id="offer.application.allowed.file-size" />
                                    </label>
                                </div>
                            </fieldset>
                        </form>
                        <div className="buttons">
                            <PxdButton
                                handleButtonClick={() => handleSubmit()}
                                name={"Candidater"}
                                isDisabled={envoiEnCours}
                                customClass="btn-rounded"
                            />
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </div>
    );
}

export default ApplicationForm;
