import React from 'react';
import { injectIntl } from 'react-intl';

import './filter-level.scss'
import PxdDropdownForm from "../../../../../../app-shared/components/stateful/pxd-form/pxd-dropdown-form/pxd-dropdown-form";

class FilterLevel extends React.Component {

    render() {
        return (
            <div className="offers-filter offers-filter_level">
                <h2 className="mobile-only filter-title">
                    <span className="icon-hat"/>
                    Mon niveau
                </h2>
                <div className="offers-filter-form offers-filter_level-form">
                    <div className="w100">
                        <PxdDropdownForm placeholder={this.props.intl.formatMessage({ id: 'search.advanced.level.dropdown.placeholder' })}
                                         options={this.props.referentialStudyLevels}
                                         value={this.props.criteria.studyLevels}
                                         keyName="studyLevels"
                                         enablePlaceholder={true}
                                         handleChange={this.props.handleChangeDropDown}
                                         ariaLabel={this.props.intl.formatMessage({ id: 'search.advanced.level.dropdown.ariaLabel' })}
                                         customClass="form-input-empty"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(FilterLevel);
