import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import sanitizeHtml from "sanitize-html";
import OfferService from "../../../../config/services/offer.service";
import PxdLoader from "../../../../app-shared/components/stateless/pxd-loader/pxd-loader";
import Footer from "../../../../theme/components/footer/footer";
import ScrollToTop from "../../../../theme/components/scroll-to-top/scroll-to-top";
import OfferListHeader from "../offer-list/internal-components/offer-list-header/offer-list-header";
import { AccessibilityContext } from "../../utils/accessibility-context";
import OfferContent from "../../../../theme/components/offer-content/offer-content";
import slugify from "../../../../theme/utils/parse.helpers";
import "./offer.scss";
import OfferTypeEnum from "../../../../utils/enum/offer-type-enum";

function Offer(props) {
    const {
        match: { params },
    } = props;

    const { offerUrl } = params;
    const { history } = props;
    const { t } = useTranslation();
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);

    const [offer, setOffer] = useState({});
    const [cardInfo, setCardInfo] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [mailto, setMailto] = useState("");
    const [searchUrl, setSearchUrl] = useState(null);

    useEffect(() => {
        if (history.location.state?.searchUrl) {
            setSearchUrl(history.location.state.searchUrl);
        }
    }, [history]);

    const getOfferId = (url) => {
        const urlArr = url.split("-");
        if (urlArr.length > 0) return urlArr[urlArr.length - 1];
        return undefined;
    };

    const toStringName = (array) => array.map(({ name }) => name).join(" / ");

    const formatPeriodMessage = (duration, durationType) => {
        if (duration !== null && duration !== "" && durationType !== null && durationType !== "") {
            return `${duration} ${durationType ? t(durationType) : ""}`;
        }
        return "";
    };

    const formatLocationMessage = (city, abroadCountry) => {
        let message = city.toUpperCase();
        if (abroadCountry && abroadCountry !== "") {
            message += ` (${abroadCountry.toUpperCase()})`;
        }
        return message;
    };

    const getCardInfo = (offerRes) => [
        { key: t("offer-card.job"), value: offerRes.title },
        { key: t("offer-card.domain"), value: toStringName(offerRes.businessDomains) },
        { key: t("offer-card.sub-domain"), value: toStringName(offerRes.subBusinessDomains) },
        { key: t("offer-card.level"), value: toStringName(offerRes.studyLevels) },
        { key: t("offer-card.preparedDiploma"), value: offerRes.preparedDiploma },
        {
            key: t("offer-card.period"),
            value:
                offerRes.offerType.nameOfferType !== OfferTypeEnum.junior_internship
                    ? formatPeriodMessage(offerRes.duration, offerRes.durationType)
                    : t("offer-card.one-week"),
        },
        { key: t("offer-card.entity"), value: offerRes.direction.name },
        { key: t("offer-card.city"), value: formatLocationMessage(offerRes.city, offerRes.abroadCountry) },
        {
            key: t("offer-card.salary"),
            value: offerRes.wages && offerRes.wages.length > 0 ? offerRes.wages[0].name : "",
        },
        {
            key: t("offer-card.catering"),
            value: offerRes.catering && offerRes.catering.length > 0 ? offerRes.catering[0].name : "",
        },
        {
            key: t("offer-card.housing"),
            value: offerRes.accommodation && offerRes.accommodation.length > 0 ? offerRes.accommodation[0].name : "",
        },
    ];

    const generateMailto = (offerRes) => {
        const mail = `mailto:${offerRes.contactEmail}?subject=${t("offer-front.mail-object")}${offerRes.reference}&body=${t(
            "offer-front.mail-body",
        )}`;
        setMailto(mail);
    };

    useEffect(() => {
        if (window.tarteaucitron.state.eulerian) {
            EA_push(["path", "offres"]);
        }

        setShowLoader(true);

        let firstVisit = false;
        const offerId = getOfferId(offerUrl);

        if (!offerId) {
            console.error("Bad Id");
            return history.push("/404");
        }

        const localStats = localStorage.getItem("offers");
        if (!localStats) {
            localStorage.setItem("offers", JSON.stringify({ [offerId]: true }));
            firstVisit = true;
        } else {
            const visits = JSON.parse(localStats);
            if (!visits[offerId]) {
                localStorage.setItem("offers", JSON.stringify({ ...visits, [offerId]: true }));
                firstVisit = true;
            }
        }

        OfferService.getOffer(offerId, firstVisit)
            .then((response) => {
                const url = `${slugify(response.data.title)}-${offerId}`;
                if (url !== offerUrl) history.replace("/404");

                if (response.data.offerDescription) {
                    response.data.offerDescription = sanitizeHtml(response.data.offerDescription, {
                        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h1", "h2", "sup"]),
                    });
                }
                if (response.data.globalDescription) {
                    response.data.globalDescription = sanitizeHtml(response.data.globalDescription, {
                        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h1", "h2", "sup"]),
                    });
                }
                const card = getCardInfo(response.data);
                generateMailto(response.data);
                setOffer(response.data);
                if (window.tarteaucitron.state.eulerian) {
                    EA_push([
                        "path",
                        `offre/${response.data.offerType.titleAbbrOfferType}/${response.data.offerId}-${response.data.title}`,
                    ]);
                }
                setCardInfo(card);
                setShowLoader(false);
            })
            .catch((err) => {
                console.error(err);
                history.push("/404");
            });
    }, []);

    const goPreviousPage = () => {
        if (history.location.state?.searchUrl !== null) {
            history.push({
                pathname: "/offers",
                search: searchUrl,
            });
        } else {
            history.push("/offers");
        }
    };

    return (
        <div className={`offer ${accessibilityIsActivated ? "accessible" : ""}`}>
            <ScrollToTop />
            <OfferListHeader
                links={[{ name: t("offer-list.your-search"), goToLink: goPreviousPage }]}
                pageName={t("offer-front.offer")}
                title={offer.title}
                subtitle={offer.direction && <span className="entity">{offer.direction.name}</span>}
                disabilityCommittedEmployer={offer.disabilityCommittedEmployer}
            />
            {!showLoader ? (
                <OfferContent offer={offer} cardInfo={cardInfo} mailto={mailto} searchUrl={searchUrl} />
            ) : (
                <PxdLoader showLoader={showLoader} customClass={"in-content"} />
            )}
            <Footer />
        </div>
    );
}

export default Offer;
