import { emailPattern, namePattern, phoneNumberPattern } from "../../../utils/constants/regex";

const regexValidators = {
    email: emailPattern,
    name: namePattern,
    phoneNumber: phoneNumberPattern,
};

const checkObjectValid = (object, rules, validateAll) => {
    rules.allValid = true;

    Object.entries(rules).filter(([key, value]) => {
        if (key !== "allValid") {
            const { required, regex, maxLength, isObject, oneRequired, radios } = value;

            // Validate all, detect empty fields
            if (!object[key] && required && validateAll) {
                value.isValid = false;
            }
            // validate only defined fields
            else if (object[key] !== undefined) {
                value.isValid = true;
                if (
                    (required && object[key].length <= 0) ||
                    (regex && !regexValidators[regex].test(object[key])) ||
                    (maxLength && object[key].length > maxLength) ||
                    (isObject && !(object[key] instanceof Object))
                ) {
                    value.isValid = false;
                }
            }
            // validate radio button
            else if (radios) {
                if (oneRequired && (radios.filter((fieldId) => object[fieldId] !== undefined).length === radios.length || validateAll)) {
                    value.isValid = radios.filter((fieldId) => object[fieldId] === true).length === 1;
                }
            }

            if (value.isValid === false) {
                rules.allValid = false;
            }
        }
    });
    return rules;
};

export default checkObjectValid;
