import React from "react";
import "./pxd-dropdown-group-selectable.scss";

class PxdDropdownGroupSelectable extends React.Component {
    render() {
        return (
            <span className="field-select">
                <select
                    id={`${this.props.id || ""}`}
                    className={`form-input ${this.props.hasError !== undefined && !this.props.hasError ? "has-error" : ""} ${
                        this.props.value === "" ? "form-input-empty" : ""
                    } ${this.props.customClass}`}
                    value={this.props.value || ""}
                    onChange={(e) => this.props.handleChange(e, this.props.keyName)}
                    aria-label={this.props.ariaLabel}
                    aria-required={this.props.ariaRequired}>
                    <option value="" disabled={!this.props.enablePlaceholder}>
                        {this.props.placeholder}
                    </option>
                    {this.props.options.map((optionGroup) => [
                        <>
                            <option className="optionGroup" key={optionGroup.id} label={optionGroup.name} value={`GR:${optionGroup.id}`}>
                                {optionGroup.name}
                            </option>
                            {optionGroup[this.props.subKeyName].map((option) => [
                                <option className="optionChild" key={option.id} value={option.id}>
                                    &nbsp;&nbsp;&nbsp;{option.name}
                                </option>,
                            ])}
                        </>,
                    ])}
                </select>
            </span>
        );
    }
}

export default PxdDropdownGroupSelectable;
