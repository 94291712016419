import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import FilterOfferType from "../filter-offer-type/filter-offer-type";
import { OfferFiltersContext } from "../../offers-filter-context";
import FilterMyJob from "../filter-my-job/filter-my-job";
import FilterMyLocation from "../filter-my-location/filter-my-location";
import FilterMyLevel from "../filter-my-level/filter-my-level";
import "./offer-list-filters.scss";

const OfferListFilters = props => {
    const { className = "" } = props;
    const [activeFilter, setActiveFilter] = useState(0);
    const { t } = useTranslation();
    const { offerCount, jobKeywords, locationKeywords, studyLevelKeywords, contractKeywords } = React.useContext(OfferFiltersContext);
    const wrapperRef = useRef(null);

    const trimKeywords = arr => {
        if (!arr || arr.length <= 0) return {};
        const count = arr
            .map(key => key.name.trim())
            .join("| ")
            .substring(0, 40)
            .split("| ").length;
        const trimmed = arr
            .map(key => key.name.trim())
            .slice(0, count)
            .join(", ");

        return { trimmed: arr.length - count > 0 ? `${trimmed} ...` : trimmed, count: arr.length - count };
    };

    const filterItems = [
        {
            itemNumber: 1,
            icon: "case",
            label: "offer-list.my-job",
            keywords: trimKeywords(jobKeywords)
        },
        {
            itemNumber: 2,
            icon: "location",
            label: "offer-list.my-location",
            keywords: trimKeywords(locationKeywords)
        },
        {
            itemNumber: 3,
            icon: "hat",
            label: "offer-list.my-level",
            keywords: trimKeywords(studyLevelKeywords)
        },
        {
            itemNumber: 4,
            icon: "diploma",
            label: "offer-list.my-contract",
            keywords: trimKeywords(contractKeywords)
        }
    ];

    const generateFilterItem = ({ itemNumber, icon, label, keywords }) => {
        const toggleFilter = filterNumber => () => {
            if (activeFilter !== filterNumber) {
                setActiveFilter(filterNumber);
            } else {
                setActiveFilter(0);
            }
        };

        const { trimmed, count } = keywords;

        return (
            <div className={`filter-item-wrapper ${activeFilter === itemNumber ? "active" : ""}`} key={label}>
                <div className="fiw">
                    <button type="button" className="filter-item" onClick={toggleFilter(itemNumber)}>
                        <div className="filter-description">
                            <span className="filter-icon">
                                <PxdIcon iconName={icon} />
                            </span>
                            <div className="description-text">{t(label)}</div>
                        </div>
                        <span className="dropdown-icon">
                            <PxdIcon iconName="arrow-bottom" />
                        </span>
                    </button>
                </div>
                <div className="filter-item-selected">
                    {trimmed || ""}
                    {count && count > 0 ? <span className="filter-item-selected__count">{` (+${count})`}</span> : ""}
                </div>
            </div>
        );
    };

    useEffect(() => {
        const handleWindowClick = e => {
            if (!wrapperRef.current.contains(e.target)) {
                setActiveFilter(0);
            }
        };
        window.addEventListener("click", handleWindowClick);

        return () => {
            window.removeEventListener("click", handleWindowClick);
        };
    }, []);

    return (
        <div className={`offer-list-filters ${className}`} ref={wrapperRef}>
            <div className="filters-list">{filterItems.map(generateFilterItem)}</div>
            <div className={`popin-container ${activeFilter !== 0 ? "show" : ""}`}>
                {activeFilter === 1 && <FilterMyJob />}
                {activeFilter === 2 && <FilterMyLocation />}
                {activeFilter === 3 && <FilterMyLevel />}
                {activeFilter === 4 && <FilterOfferType />}
                <div className="number-results">{`${offerCount} ${t("offer-list.result-found")} `}</div>
            </div>
        </div>
    );
};

export default OfferListFilters;
