const slugify = title => {
    // special characters
    const str = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;&@%!#\"'’";
    const res = "aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz---------------";
    const re = new RegExp(str.split("").join("|"), "g");

    // replace spaces, replace special characters and remove multiple -
    return title
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(re, char => res.charAt(str.indexOf(char)))
        .replace(/--+/g, "-");
};

export default slugify;
