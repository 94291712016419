import { useState } from "react";
import { withRouter } from "react-router-dom";
import http from "./http-service";

const HttpInterceptor = (props) => {
    const ignoredPaths = ["/change-password-token", "/login", "/change-password-required"];

    useState(() => {
        http.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.response) {
                    const { status } = error.response;
                    const { pathname } = props.history.location;
                    if (status === 401 && error.request.responseURL !== "/admin/users/current" && ignoredPaths.indexOf(pathname) === -1) {
                        props.history.push("/login");
                    }
                } else {
                    throw error;
                }
                return Promise.reject(error);
            },
        );
    }, []);
    return props.children;
};

export default withRouter(HttpInterceptor);
