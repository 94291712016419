import React from "react";
import { injectIntl } from "react-intl";

import "./filter-domain.scss";
import PxdInputForm from "../../../../../../app-shared/components/stateful/pxd-form/pxd-input-form/pxd-input-form";
import PxdTags from "../../../../../../app-shared/components/stateful/pxd-tags/pxd-tags";
import PxdDropdownGroupSelectable from "../../../../../../app-shared/components/stateful/pxd-form/pxd-dropdown-group-selectable/pxd-dropdown-group-selectable";

class FilterDomain extends React.Component {
    render() {
        return (
            <div className="offers-filter offers-filter_domain">
                <h2 className="mobile-only filter-title">
                    <span className="icon-case" />
                    Mon métier
                </h2>
                <div className="offers-filter-form offers-filter_domain-form">
                    <div className="choose-domain">
                        <PxdDropdownGroupSelectable
                            placeholder={this.props.intl.formatMessage({ id: "search.advanced.domain.dropdown.placeholder" })}
                            options={this.props.referentialBusinessDomains}
                            handleChange={this.props.handleChangeDropDown}
                            keyName={"subBusinessDomains"}
                            subKeyName={"subBusinessDomains"}
                            referential={"referentialBusinessDomains"}
                            ariaLabel={this.props.intl.formatMessage({ id: "search.advanced.domain.dropdown.ariaLabel" })}
                            customClass="form-input-empty"
                        />
                    </div>
                    <div className="choose-job">
                        <PxdInputForm
                            placeholder={this.props.intl.formatMessage({ id: "search.advanced.domain.input.placeholder" })}
                            ariaLabel={this.props.intl.formatMessage({ id: "search.advanced.domain.input.ariaLabel" })}
                            handleEnterKeyPress={this.props.handleChangeDropDown}
                            handleButtonClick={this.props.handleButtonClick}
                            keyName={"jobs"}
                            buttonAdd="true"
                        />
                    </div>
                </div>
                <div className="offers-filter-tags offers-filter_domain-tag">
                    <PxdTags tags={this.props.tags} handleTagRemove={this.props.handleTagRemove} />
                </div>
            </div>
        );
    }
}

export default injectIntl(FilterDomain);
