import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../../../theme/components/footer/footer";
import OfferListHeader from "../offer-list/internal-components/offer-list-header/offer-list-header";
import { AccessibilityContext } from "../../utils/accessibility-context";
import ScrollToTop from "../../../../theme/components/scroll-to-top/scroll-to-top";
import "./sitemap.scss";

function SiteMap() {
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);

    return (
        <div className={`sitemap ${accessibilityIsActivated ? "accessible" : ""}`}>
            <ScrollToTop />
            <OfferListHeader pageName="Plan du site" title="Plan du site" />
            <article className="sitemap__content">
                <ul>
                    <li>
                        <Link to="/">Accueil du site</Link>
                    </li>
                    <li>
                        <Link to="/offers">Liste des offres</Link>
                    </li>
                    <li>
                        <Link to="/search">Recherche avancée</Link>
                    </li>
                    <li>
                        <Link to="/conditions-generales-utilisation">Conditions générales d'utilisation</Link>
                    </li>
                    <li>
                        <Link to="/mentions-legales">Mentions légales</Link>
                    </li>
                    <li>
                        <Link to="/plan-site">Plan du site</Link>
                    </li>
                </ul>
            </article>
            <Footer />
        </div>
    );
}

export default SiteMap;
