import React from "react";
import { v4 as uuid } from "uuid";
import "./pxd-custom-select.scss";
import PxdIcon from "../pxd-icon/pxd-icon";

function PxdCustomSelect(props) {
    const { options = [], onChange = () => {} } = props;

    const generateOption = data => (
        <option
            value={data.value}
            key={data.value || uuid()}
            disabled={data.disabled}
            className={`pxd-custom-select-option ${data.className ? data.className : ""}`}>
            {data.name}
        </option>
    );

    return (
        <div className="pxd-custom-select-wrapper">
            <select className="pxd-custom-select" onChange={onChange} value="value">
                {options.map(generateOption)}
            </select>
            <PxdIcon iconName="arrow-bottom" />
        </div>
    );
}

export default PxdCustomSelect;
