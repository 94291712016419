let baseUrl = process.env.REACT_APP_BASE_URL;
const offerYearsLength = 3;

if (baseUrl) {
    baseUrl = baseUrl.replace(/ /g, "");
}

const config = {
    baseUrl,
    offerYearsLength
};

export default config;
