import api from "../../app-shared/components/utils/http/http-service";

class ApplicationService {
    static submitApplication(application) {
        const formData = new FormData();
        formData.append("offerReference", application.offerReference);
        formData.append("offerContactEmail", application.offerContactEmail);
        formData.append("lastName", application.lastName);
        formData.append("firstName", application.firstName);
        formData.append("applicationContent", application.applicationContent);
        formData.append("phoneNumber", application.phoneNumber);
        formData.append("email", application.email);
        formData.append("resume", application.resumeFile);
        formData.append("coverLetter", application.coverLetterFile);

        return api.put("/offer_application", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
}

export default ApplicationService;
