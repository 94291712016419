import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PxdButton from "../../../../app-shared/components/stateless/pxd-button/pxd-button";

import qs from "query-string";
import { Link } from "react-router-dom";
import qpu from "../../../../app-shared/components/utils/query/query-parser-utils";
import OfferService from "../../../../config/services/offer.service";
import ReferentialService from "../../../../config/services/referential.service";
import logo from "../../../../theme/assets/img/logo_ministere-des-armees.svg";
import Footer from "../../../../theme/components/footer/footer";
import { OfferFiltersContext } from "../offer-list/offers-filter-context";
import FilterContract from "./pxd-filter/pxd-filter-contract/filter-contract";
import FilterDomain from "./pxd-filter/pxd-filter-domain/filter-domain";
import FilterLevel from "./pxd-filter/pxd-filter-level/filter-level";
import FilterLocation from "./pxd-filter/pxd-filter-location/filter-location";
import "./search-advanced.scss";

class SearchAdvanced extends React.Component {
    static contextType = OfferFiltersContext;

    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search);
        const criteria = this.getCriteria(query);

        this.state = {
            criteria,
            dataSize: 0,
            query: "",
            tags: {
                domain: [],
                location: [],
                offerTypes: [],
            },
            referential: {
                businessDomains: [],
                regions: [],
                departments: [],
                studyLevels: [],
                offerTypes: [],
            },
        };

        this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
        this.handleCriteriaChangeDropDown = this.handleCriteriaChangeDropDown.bind(this);
        this.handleCriteriaTagRemove = this.handleCriteriaTagRemove.bind(this);
        this.handleCriteriaCheckboxChange = this.handleCriteriaCheckboxChange.bind(this);
        this.updateCriteriaTagsAndQuery = this.updateCriteriaTagsAndQuery.bind(this);
        this.getOffersCount = this.getOffersCount.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        if (this.context) {
            this.context.setReload(true);
        }
        this.getReferential().then((referential) => {
            this.setState({ referential }, () => {
                this.updateCriteriaTagsAndQuery(this.state.criteria);
            });
        });
        this.getOffersCount(this.state.criteria);
    }

    async getReferential() {
        let referential = {
            departments: [],
            regions: [],
            businessDomains: [],
            studyLevels: [],
            offerTypes: [],
        };

        referential.regions = (await ReferentialService.getRegions()).data;
        referential.departments = (await ReferentialService.getDepartments()).data;
        referential.businessDomains = (await ReferentialService.getBusinessDomain()).data;
        referential.studyLevels = (await ReferentialService.getStudyLevels()).data;
        let offerTypes = (await ReferentialService.getOfferTypes()).data;
        let newDataFormat = [];
        offerTypes.map((offerType) => {
            let ot = { id: offerType.offerTypeId, name: offerType.titleOfferType, titleAbbr: offerType.titleAbbrOfferType, value: false };
            newDataFormat.push(ot);
        });
        referential.offerTypes = newDataFormat;
        return referential;
    }

    getOffersCount(criteria) {
        OfferService.getOffersCount(criteria).then((response) => {
            this.setState({ dataSize: response.data.offerSize });
        });
    }

    getCriteria(query) {
        return {
            jobs: qpu.asArray(query.jobs),
            studyLevels: query.studyLevels || undefined,
            cities: qpu.asArray(query.cities),
            regions: qpu.asArray(query.regions),
            departments: qpu.asArray(query.departments),
            subBusinessDomains: qpu.asArray(query.subBusinessDomains),
            offerTypes: qpu.asArray(query.offerTypes),
        };
    }

    handleCriteriaChange(e, key) {
        const c = { ...this.state.criteria };
        c[key] = e.target.value;

        this.updateCriteriaTagsAndQuery(c);
    }

    handleCriteriaChangeDropDown(e, key) {
        const c = { ...this.state.criteria };
        // Current for refs
        let val = e.target ? e.target.value : e.current.value;
        if (val.startsWith("GR:")) {
            key = "businessDomains";
            val = val.slice(3);
        }
        if (!c[key]) {
            c[key] = [];
        }
        if (val !== undefined && val !== "" && c[key].findIndex((e) => e === val) === -1) {
            c[key].push(val);
        }
        this.updateCriteriaTagsAndQuery(c);
    }

    handleCriteriaTagRemove(e, key, value) {
        const c = { ...this.state.criteria };
        if (c[key] && Array.isArray(c[key])) {
            const i = c[key].findIndex((a) => a === value.toString());

            if (i > -1) {
                c[key].splice(i, 1);

                this.updateCriteriaTagsAndQuery(c);
            }
        }
    }

    handleCriteriaCheckboxChange(e, key) {
        const c = { ...this.state.criteria };
        c[key] = e.target.checked || undefined;
        this.updateCriteriaTagsAndQuery(c);
    }

    getSubBusinessDomainsListForTags(criteria) {
        let subBusinessDomains = [];
        if (criteria.subBusinessDomains) {
            subBusinessDomains = criteria.subBusinessDomains
                .map((d) => {
                    const subDomains = this.state.referential.businessDomains
                        .map((domain) => {
                            const subDomain = domain.subBusinessDomains.find((el) => el.id === d.toString());
                            if (subDomain) {
                                return { name: subDomain.name, keyName: "subBusinessDomains", value: d.toString() };
                            } else {
                                return undefined;
                            }
                        })
                        .filter((e) => e);

                    if (subDomains && subDomains.length > 0) {
                        return subDomains[0];
                    } else {
                        return undefined;
                    }
                })
                .filter((e) => e);
        }
        return subBusinessDomains;
    }

    getDomainTags(criteria) {
        let tags = [];

        if (criteria.jobs) {
            const jobs = criteria.jobs.map((e) => {
                return { name: e, keyName: "jobs", value: e };
            });
            tags = tags.concat(jobs);
        }
        if (criteria.businessDomains) {
            const businessDomains = criteria.businessDomains.map((id) => {
                const domain = this.state.referential.businessDomains.find((el) => el.id === id);
                return { name: domain.name, keyName: "businessDomains", value: id };
            });
            tags = tags.concat(businessDomains);
        }

        tags = tags.concat(this.getSubBusinessDomainsListForTags(criteria));
        return tags;
    }

    getDepartmentsListForTags(criteria) {
        let departments = [];
        if (criteria.departments) {
            departments = criteria.departments
                .map((d) => {
                    const dept = this.state.referential.departments.find((e) => e.id === d.toString());
                    if (dept) {
                        return { name: dept.name, keyName: "departments", value: d.toString() };
                    } else {
                        return undefined;
                    }
                })
                .filter((e) => e);
        }
        return departments;
    }

    getRegionListForTags(criteria) {
        let regs = [];
        if (criteria.regions) {
            regs = criteria.regions
                .map((d) => {
                    const reg = this.state.referential.regions.find((e) => e.id === d.toString());
                    if (reg) {
                        return { name: reg.name, keyName: "regions", value: d.toString() };
                    } else {
                        return undefined;
                    }
                })
                .filter((e) => e);
        }
        return regs;
    }

    getOfferTypesTags(criteria) {
        let tags = [];

        if (criteria.offerTypes) {
            const offerTypes = criteria.offerTypes.map((id) => {
                const offerType = this.state.referential.offerTypes.find((el) => el.id.toString() === id);
                return { name: offerType.name, keyName: "offerTypes", value: id };
            });
            tags = tags.concat(offerTypes);
        }
        return tags;
    }

    getLocationTags(criteria) {
        let tags = [];

        if (criteria.cities) {
            const cities = criteria.cities.map((e) => {
                return { name: e, keyName: "cities", value: e };
            });
            tags = tags.concat(cities);
        }

        tags = tags.concat(this.getDepartmentsListForTags(criteria));
        tags = tags.concat(this.getRegionListForTags(criteria));

        return tags;
    }

    updateCriteriaTagsAndQuery(criteria) {
        let query = qs.stringify(criteria);
        const tags = {
            domain: this.getDomainTags(criteria),
            location: this.getLocationTags(criteria),
            offerTypes: this.getOfferTypesTags(criteria),
        };

        query = "?" + query;
        this.props.history.push(query);
        this.getOffersCount(criteria);

        this.setState({ criteria, query, tags });
    }

    handleSearch() {
        if (window.tarteaucitron.state.eulerian) {
            // send cities
            if (this.state.criteria.cities) {
                this.state.criteria.cities.forEach((city) => {
                    EA_push(["path", `recherche/ville/${city}`]);
                });
            }
            // send departments
            if (this.state.criteria.departments) {
                this.getDepartmentsListForTags(this.state.criteria).forEach((department) => {
                    EA_push(["path", `recherche/departement/${department.name}`]);
                });
            }
            // send regions
            if (this.state.criteria.regions) {
                this.getRegionListForTags(this.state.criteria).forEach((region) => {
                    EA_push(["path", `recherche/region/${region.name}`]);
                });
            }
            // send studylevel
            if (this.state.criteria.studyLevels) {
                const studyLevelName = this.state.referential.studyLevels.find(
                    (el) => el.id.toString() === this.state.criteria.studyLevels,
                ).name;
                EA_push(["path", `recherche/niveau/${studyLevelName}`]);
            }
            // send offerTypes
            if (this.state.criteria.offerTypes) {
                this.getOfferTypesTags(this.state.criteria).forEach((offerType) => {
                    EA_push(["path", `recherche/type/${offerType.name}`]);
                });
            }
            // send subBusinessDomains
            if (this.state.criteria.subBusinessDomains) {
                this.getSubBusinessDomainsListForTags(this.state.criteria).forEach((subBusinessDomain) => {
                    EA_push(["path", `recherche/sous_domaine/${subBusinessDomain.name}`]);
                });
            }
        }
    }

    render() {
        return (
            <div className="search-advanced">
                <header className="top-search">
                    <a
                        className="logo"
                        href="https://www.defense.gouv.fr/"
                        rel="noopener noreferrer"
                        target="_blank"
                        title="Retour à l'accueil">
                        <img
                            src={logo}
                            title="République Française - Ministère des armées"
                            alt="République Française - Ministère des armées"
                        />
                    </a>
                    <h1 className="title">
                        <FormattedMessage id="search.advanced.title" />
                    </h1>
                    <Link to={"/"}>
                        <button type="button" className="btn-icon" title="Quitter la recherche avancée">
                            <span className="icon-delete" />
                        </button>
                    </Link>
                </header>
                <div className="container-filters wrapper">
                    <section className="contract">
                        <FilterContract
                            criteria={this.state.criteria}
                            handleChangeDropDown={this.handleCriteriaChangeDropDown}
                            handleCheckboxChange={this.handleCriteriaCheckboxChange}
                            handleTagRemove={this.handleCriteriaTagRemove}
                            offerTypesReferential={this.state.referential.offerTypes}
                            tags={this.state.tags.offerTypes}
                        />
                    </section>
                    <section className="level">
                        <FilterLevel
                            criteria={this.state.criteria}
                            handleChangeDropDown={this.handleCriteriaChange}
                            referentialStudyLevels={this.state.referential.studyLevels}
                        />
                    </section>
                    <section className="domain">
                        <FilterDomain
                            criteria={this.state.criteria}
                            handleChangeDropDown={this.handleCriteriaChangeDropDown}
                            handleButtonClick={this.handleCriteriaChangeDropDown}
                            handleTagRemove={this.handleCriteriaTagRemove}
                            referentialBusinessDomains={this.state.referential.businessDomains}
                            tags={this.state.tags.domain}
                        />
                    </section>
                    <section className="location">
                        <FilterLocation
                            criteria={this.state.criteria}
                            handleChangeDropDown={this.handleCriteriaChangeDropDown}
                            handleButtonClick={this.handleCriteriaChangeDropDown}
                            handleTagRemove={this.handleCriteriaTagRemove}
                            referentialDepartments={this.state.referential.departments}
                            referentialRegions={this.state.referential.regions}
                            tags={this.state.tags.location}
                            handleCheckboxChange={this.handleCriteriaCheckboxChange}
                        />
                    </section>
                    <div className="container-btn-results">
                        {this.state.dataSize && this.state.dataSize !== 0 ? (
                            <Link to={`/offers${this.state.query}`}>
                                <PxdButton
                                    handleButtonClick={this.handleSearch}
                                    name={
                                        this.props.intl.formatMessage({ id: "search.advanced.button.submit" }) +
                                        " (" +
                                        this.state.dataSize +
                                        ")"
                                    }
                                    customClass="btn-rounded small-btn"
                                />
                            </Link>
                        ) : (
                            <PxdButton
                                isDisabled={true}
                                name={this.props.intl.formatMessage({ id: "search.advanced.button.submit.disabled" })}
                                customClass="btn-rounded small-btn"
                            />
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default injectIntl(SearchAdvanced);

