import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./footer.scss";
import { getCurrent } from "../../../app-admin/content-management/services/page-content.service";
import { AccessibilityContext } from "../../../app-frontal/components/utils/accessibility-context";

function Footer({ accessibility = false }) {
    const { t } = useTranslation();
    const { toggleAccessibility } = React.useContext(AccessibilityContext);
    const [footerLinks, setFooterLinks] = useState();
    // Get current year
    const year = moment().format("YYYY");

    useEffect(() => {
        getCurrent("social-networks").then((rsp) => {
            const mappedContents = rsp.data.reduce((acc, item) => {
                acc[item.contentId] = item.content;
                return acc;
            }, {});
            setFooterLinks(mappedContents);
        });
    }, []);

    return (
        <footer className="footer">
            <div className="external-links">
                <a className="external-link" href={footerLinks?.["social-network1.link"]} rel="noopener noreferrer" target="_blank">
                    <img src={footerLinks?.["social-network1.img"]} width="20px" height="20px" alt="social-network1" />
                </a>
                <a className="external-link" href={footerLinks?.["social-network2.link"]} rel="noopener noreferrer" target="_blank">
                    <img src={footerLinks?.["social-network2.img"]} width="20px" height="20px" alt="social-network2" />
                </a>
                <a className="external-link" href={footerLinks?.["social-network3.link"]} rel="noopener noreferrer" target="_blank">
                    <img src={footerLinks?.["social-network3.img"]} width="20px" height="20px" alt="social-network3" />
                </a>
            </div>
            <div className="interactive">
                <div className="footer-controls">
                    <button className="accessibility-button" type="button" onClick={toggleAccessibility}>
                        {!accessibility ? t("footer.accessibility") : t("footer.classic")}
                    </button>
                </div>
                <div className="internal-links">
                    <Link className="internal-link" to="/accessibilite">
                        {t("footer.accessibility-link")}
                    </Link>
                    <span className="dash">-</span>
                    <Link className="internal-link" to="/conditions-generales-utilisation">
                        {t("footer.cgu")}
                    </Link>
                    <span className="dash">-</span>
                    <Link className="internal-link" to="/mentions-legales">
                        {t("footer.mentions-legales")}
                    </Link>
                    <span className="dash">-</span>
                    <Link className="internal-link" to="/plan-site">
                        {t("footer.site-map")}
                    </Link>
                </div>
            </div>
            {/* \u00A9 is the copyright character in unicode */}
            <div className="copyright">{`${"\u00A9"} ${t("footer.copyright")} ${year}`}</div>
        </footer>
    );
}

export default Footer;
