import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import qs from "query-string";
import sanitizeHtml from "sanitize-html";

import PxdButton from "../../../../app-shared/components/stateless/pxd-button/pxd-button";
import OfferCardList from "./internal-components/offer-cards-list/offer-card-list";

import "./home.scss";
import logo from "../../../../theme/assets/img/logo_ministere-des-armees.svg";

import ReferentialService from "../../../../config/services/referential.service";
import PxdDropdownGroupSelectable from "../../../../app-shared/components/stateful/pxd-form/pxd-dropdown-group-selectable/pxd-dropdown-group-selectable";
import PxdDropdownForm from "../../../../app-shared/components/stateful/pxd-form/pxd-dropdown-form/pxd-dropdown-form";
import PageContentService from "../../../../config/services/page-content.service";
import TestimonyCardList from "./internal-components/testimony-list/testimony-list";
import Footer from "../../../../theme/components/footer/footer";
import { OfferFiltersContext } from "../offer-list/offers-filter-context";

class Home extends React.Component {
    static contextType = OfferFiltersContext;

    constructor(props) {
        super(props);

        this.state = {
            criteria: {
                regions: "",
                businessDomains: "",
                subBusinessDomains: "",
            },

            query: "",
            content: {},
            referential: {
                regions: [],
                businessDomains: [],
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.launchSearch = this.launchSearch.bind(this);

    }

    componentDidMount() {
        if (window.tarteaucitron.state.eulerian) {
            EA_push(["path", "accueil"]);
        }

        this.getReferential().then(referential => {
            this.setState({ referential });
        });

        //re-initialise offer list filters context
        if(this.context){
            this.context.setReload(true);
        }
        PageContentService.getCurrent("home")
            .then(rsp => {
                const data = rsp.data || [];
                const mappedContents = data.reduce((acc, item) => {
                    if (item.contentId === "block1.longtext" || item.contentId === "block2.longtext" || item.contentId === "block3.longtext") {
                        acc[item.contentId] = sanitizeHtml(item.content, { allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h1", "h2", "sup"]) });
                    } else {
                        acc[item.contentId] = item.content;
                    }
                    return acc;
                }, {});
                this.setState({
                    content: mappedContents
                });
            })
            .catch(err => console.error(err));
    }

    async getReferential() {
        const referential = {
            regions: [],
            businessDomains: [],
            offerTypes: [],
        };

        referential.regions = (await ReferentialService.getRegions()).data;
        referential.businessDomains = (await ReferentialService.getBusinessDomain()).data;
        let offerTypes = (await ReferentialService.getOfferTypes()).data;
        let newDataFormat = []
        offerTypes.map(offerType =>{
            let ot = {id : offerType.offerTypeId, name : offerType.titleOfferType, titleAbbr : offerType.titleAbbrOfferType}
            newDataFormat.push(ot)
        })
        referential.offerTypes = newDataFormat
        return referential;
    }

    handleChange(e, keyName) {
        const criteria = { ...this.state.criteria };

        if(keyName === "subBusinessDomains" || keyName === "businessDomains") {
            criteria["subBusinessDomains"] = undefined;
            criteria["businessDomains"] = undefined;
        }

        if(keyName === "regions") {
            criteria["regions"] = undefined;
        }

        let val = e.target ? e.target.value : e.current.value;
        let val2 = val;
        if (val.startsWith("GR:")) {
            keyName = "businessDomains";
            val2 = val.slice(3);
        }
        criteria[keyName] = val2;

        // Reformat criteria for businessDomains / Removing GR:
        let criteriaForQuery = {...criteria};

        if(criteriaForQuery.businessDomains?.startsWith("GR:")) {
            criteriaForQuery.businessDomains = criteriaForQuery.businessDomains.slice(3)
        }
        let query = qs.stringify(criteriaForQuery);

        if (query && query !== "") {
            query = `?${query}`;
        }

        criteria[keyName] = val;
        this.setState({ criteria, query });
    }

    launchSearch(event) {
        event.preventDefault();
        this.props.history.push(`/offers${this.state.query}`);
    }

    render() {
        return (
            <div className="home">
                <header className="home_header">
                    <div className="home_header-inner">
                        <a href="https://www.defense.gouv.fr/" rel="noopener noreferrer" target="_blank">
                            <img src={logo} className="logo" title="République Française - Ministère des armées" alt="République Française - Ministère des armées" />
                        </a>
                        <h1>
                            <span className="color">
                                <FormattedMessage id="page.home.title-top" />
                            </span>
                            <FormattedMessage id="page.home.title-bottom" />
                        </h1>
                        <form className="home_header-form" onSubmit={this.launchSearch}>
                            <div className="home_header-form-input">
                                <PxdDropdownForm
                                    placeholder={this.props.intl.formatMessage({ id: "search.advanced.location.contract.placeholder" })}
                                    options={this.state.referential.offerTypes}
                                    handleChange={this.handleChange}
                                    keyName="offerTypes"
                                    ariaLabel={this.props.intl.formatMessage({ id: "search.advanced.domain.contract.ariaLabel" })}
                                    enablePlaceholder
                                    value={this.state.criteria.offerTypes}
                                    customClass="form-input-empty"
                                />
                                <PxdDropdownGroupSelectable
                                    placeholder={this.props.intl.formatMessage({ id: 'search.advanced.domain.dropdown.placeholder' })}
                                    options={this.state.referential.businessDomains}
                                    handleChange={this.handleChange}
                                    keyName={'subBusinessDomains'}
                                    subKeyName={'subBusinessDomains'}
                                    referential={'referentialBusinessDomains'}
                                    value={this.state.criteria.subBusinessDomains || this.state.criteria.businessDomains}
                                    enablePlaceholder
                                    ariaLabel={this.props.intl.formatMessage({ id: 'search.advanced.domain.dropdown.ariaLabel' })}
                                    customClass="form-input-empty"/>
                                <PxdDropdownForm
                                    placeholder={this.props.intl.formatMessage({ id: "search.advanced.location.district.placeholder" })}
                                    options={this.state.referential.regions}
                                    handleChange={this.handleChange}
                                    keyName="regions"
                                    ariaLabel={this.props.intl.formatMessage({ id: "search.advanced.location.district.ariaLabel" })}
                                    enablePlaceholder
                                    value={this.state.criteria.regions}
                                    customClass="form-input-empty"
                                />
                            </div>
                            <div className="home_header-form-morecriteria">
                                <Link to={`/search${this.state.query}`}>
                                    <span className="icon-plus" />
                                    <FormattedMessage id="page.home.morecriteria" />
                                </Link>
                            </div>
                            <div className="home_header-form-submit">
                                <PxdButton
                                    name={this.props.intl.formatMessage({ id: "page.home.button.search" })}
                                    handleButtonClick={this.launchSearch}
                                    customClass="btn-rounded big-btn"
                                />
                            </div>
                            <input type="submit" hidden />
                        </form>
                    </div>
                </header>
                <article className="home_content wrapper">
                    <div className="home_content-intro">
                        <p>{this.state.content.introduction}</p>
                    </div>
                    <div className="home_content-infos">{this.state.content?.["block1.title"] && <OfferCardList contents={this.state.content} />}</div>
                </article>
                <TestimonyCardList contents={this.state.content} className="testimony-list-wrapper" />
                <Footer />
            </div>
        );
    }
}

export default injectIntl(Home);
