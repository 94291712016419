import React from 'react';

class NotificationItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            level: props.notification.level,
            message: props.notification.message
        }
    }

    render() {
        return (
            <div className={"notification notification-" + this.state.level}>
                {this.state.message}
                <button type="button" title="Supprimer la notification" className="btn-icon" onClick={() => this.props.handleButtonClose(this.props.index)}>
                    <span className="icon-delete"/>
                </button>
            </div>
        )
    }
}

export default NotificationItem;