import axios from "axios";
import config from "../../../../app.config";

const baseURL = config.baseUrl;

const credentials = process.env.REACT_APP_TYPE.replace(/ /g, "") === "ADMIN";

const http = axios.create({
    baseURL,
    withCredentials: credentials
});

export default http;
