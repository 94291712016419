import React, { useEffect, useState } from "react";
import "./filter-my-job.scss";
import { useTranslation } from "react-i18next";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import { OfferFiltersContext } from "../../offers-filter-context";
import PxdCustomSelect from "../../../../../../theme/components/pxd-custom-select/pxd-custom-select";

function FilterMyJob(props) {
    const { t } = useTranslation();
    const { className = "" } = props;
    const { domains, jobs, setJobs, subBusinessDomains, setSubBusinessDomain, businessDomains, setBusinessDomains, jobKeywords } =
        React.useContext(OfferFiltersContext);

    const [domainOptions, setDomainOptions] = useState([]);
    const [currentJob, setCurrentJob] = useState("");

    useEffect(() => {
        const options = domains.reduce((acc, item) => {
            acc.push({
                id: item.id,
                name: item.name,
                isDomain: true,
                value: `${"dom"}:${item.id}`,
                className: "opt-domain",
            });
            item.subBusinessDomains.forEach((sbd) => {
                acc.push({
                    id: sbd.id,
                    name: sbd.name,
                    value: `${"sub"}:${sbd.id}`,
                    className: "opt-subdomain",
                });
            });
            return acc;
        }, []);
        setDomainOptions(options);
    }, [domains]);

    const handleChangeDomain = (e) => {
        const { value } = e.target;
        if (value.indexOf(":") > -1) {
            const splited = value.split(":");
            if (splited.length > 1) {
                const idToSearch = splited[1];
                if (splited[0] === "dom") {
                    if (businessDomains.findIndex((bd) => bd === idToSearch) === -1) {
                        setBusinessDomains([...businessDomains, splited[1]]);
                    }
                } else if (subBusinessDomains.findIndex((bd) => bd === idToSearch) === -1) {
                    setSubBusinessDomain([...subBusinessDomains, splited[1]]);
                }
            }
        }
    };

    const removeKeyword = (type, id) => {
        if (type === "domain") {
            setBusinessDomains(businessDomains.filter((bd) => bd !== id));
        }
        if (type === "subdomain") {
            setSubBusinessDomain(subBusinessDomains.filter((sbd) => sbd !== id));
        }
        if (type === "job") {
            setJobs(jobs.filter((jb) => jb !== id));
        }
    };

    const generateKeyword = (data) => (
        <li key={data.name} className="keyword-item">
            {data.name}
            <button type="button" className="remove-keyword-button" onClick={() => removeKeyword(data.type, data.id)}>
                <PxdIcon iconName="delete" />
            </button>
        </li>
    );

    const addJob = () => {
        if (currentJob) {
            setJobs([...jobs, currentJob]);
            setCurrentJob("");
        }
    };

    const handleKeydownJob = (e) => {
        if (e.keyCode === 13) {
            addJob();
        }
    };

    return (
        <div className={`my-job-filter-wrapper ${className}`}>
            <h3 className="title">{t("offer-list.my-job")}</h3>
            <div className="filters">
                <div className="domain-select-wrapper">
                    <PxdCustomSelect
                        options={[
                            {
                                name: "Choississez un domaine",
                                value: "default",
                            },
                            ...domainOptions,
                        ]}
                        onChange={handleChangeDomain}
                        value="default"
                    />
                </div>
                <div className="choose-job-wrapper">
                    <input
                        className="input-job"
                        type="text"
                        placeholder="Saississez un métier"
                        title="Saississez un métier"
                        value={currentJob}
                        onChange={(e) => setCurrentJob(e.target.value)}
                        onKeyDown={handleKeydownJob}
                    />
                    <div onClick={addJob}>
                        <PxdIcon iconName="plus" />
                    </div>
                </div>
            </div>
            {jobKeywords && jobKeywords.length > 0 && (
                <div className="keywords-wrapper">
                    <h4 className="title">{t("offer-list.my-keywords")}</h4>
                    <ul className="keywords">{jobKeywords.map(generateKeyword)}</ul>
                </div>
            )}
        </div>
    );
}

export default FilterMyJob;
