import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import i8nextFr from "./i18next-fr.json";

i18next
    .use(initReactI18next)
    .init({
        resources: {
            fr: {
                translation: i8nextFr,
            },
        },
        lng: "fr",
        fallbackLng: "fr",
        interpolation: {
            escapeValue: false,
        },
    })
    .then();

export default i18next;
