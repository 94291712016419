import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";
import PageContentService from "../../../../config/services/page-content.service";
import Footer from "../../../../theme/components/footer/footer";
import OfferListHeader from "../offer-list/internal-components/offer-list-header/offer-list-header";
import { AccessibilityContext } from "../../utils/accessibility-context";
import "./accessibility-helper.scss";
import ScrollToTop from "../../../../theme/components/scroll-to-top/scroll-to-top";

function AccessibilityHelper() {
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);
    const [accessibility, setAccessibility] = useState("");

    useEffect(() => {
        if (window.tarteaucitron.state.eulerian) {
            EA_push(["path", "accessibilite"]);
        }

        PageContentService.getCurrent("accessibility").then((rsp) => {
            setAccessibility(
                sanitizeHtml(rsp.data[0].content, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h1", "h2", "sup"]),
                }),
            );
        });
    }, []);

    return (
        <div className={`accessibility-helper ${accessibilityIsActivated ? "accessible" : ""}`}>
            <ScrollToTop />
            <OfferListHeader pageName="Accessibilité " title="Accessibilité et aide à la navigation" />
            <article className="accessibility-helper__content">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: accessibility }} />
            </article>
            <Footer />
        </div>
    );
}

export default AccessibilityHelper;
