import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "intl";
import "./index.scss";

import "./config/i18n/i18next";

if (process.env.REACT_APP_TYPE.replace(/ /g, "") === "ADMIN") {
    require("./app-admin/index-admin.js");
} else {
    require("./app-frontal/index-frontal.js");
}
