import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OfferFiltersContext } from "../../offers-filter-context";
import OffersMoreResults from "../more-results/more-results";
import Offers from "../offers/offers";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import "./offer-list-contents.scss";

const OfferListContents = () => {
    const { t } = useTranslation();
    const { offerCount, sortOrder, setSortOrder } = React.useContext(OfferFiltersContext);

    return (
        <div className="offer-list-content">
            <h1 className="offer-list-main-title">
                {t("offer-list.offer-list")}
                {offerCount !== undefined && <span className="offer-count">{`(${offerCount})`}</span>}
            </h1>
            {offerCount === 0 ? (
                <div className="no-offers">
                    <h2 className="no-offers-title">Aucun résultat</h2>
                    <span className="no-offers-text">Essayez de modifier vos critères de recherche </span>
                    <br className="no-offers-space" />
                    <span className="no-offers-text">en supprimant ou modifiant des filtres.</span>
                </div>
            ) : (
                <>
                    <div className="sort-order-wrapper">
                        <div className="select-wrapper">
                            <select value={sortOrder} onChange={e => setSortOrder(e.target.value)} className="select-sort-order">
                                <option value="DESC">{t("offer-list.publish-at-desc")}</option>
                                <option value="ASC">{t("offer-list.publish-at-asc")}</option>
                            </select>
                            <PxdIcon iconName="arrow-bottom" />
                        </div>
                    </div>
                    <div className="offers-wrapper">
                        <Offers />
                    </div>
                    {offerCount > 10 && <OffersMoreResults />}
                </>
            )}
            <div className="edit-search">
                <Link to="/search" className="btn-edit-search">
                    Modifier ma recherche
                </Link>
            </div>
        </div>
    );
};

export default OfferListContents;
