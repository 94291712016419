import api from "../../app-shared/components/utils/http/http-service";

class ReferentialService {
    static getOfferTypes() {
        return api.get("/referential/offer-types");
    }

    static getBusinessDomain() {
        return api.get("/referential/business-domains");
    }

    static getDirections() {
        return api.get("/referential/directions");
    }

    static getSubDirections() {
        return api.get("/referential/sub-directions");
    }

    static getRegions() {
        return api.get("/referential/location/regions");
    }

    static getDepartments() {
        return api.get("/referential/location/departments");
    }

    static getStudyLevels() {
        return api.get("/referential/study-levels?field=studyLevelOrder&order=ASC");
    }

    static getTowns(query) {
        const queryParams = `query=${query}`;
        return api.get(`/referential/location/towns?${queryParams}`);
    }

    static getWages() {
        return api.get("/referential/wages");
    }

    static getAccommodation() {
        return api.get("/referential/accommodation");
    }

    static getCatering() {
        return api.get("/referential/catering");
    }

    static getBusinessDomains() {
        return api.get("/referential/business-domains");
    }
}

export default ReferentialService;
