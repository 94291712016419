import React, { useEffect, useState } from "react";
import "./filter-offer-type.scss";
import { useTranslation } from "react-i18next";
import { OfferFiltersContext } from "../../offers-filter-context";
import PxdIcon from "../../../../../../theme/components/pxd-icon/pxd-icon";
import PxdCustomSelect from "../../../../../../theme/components/pxd-custom-select/pxd-custom-select";
import { PxdSwitch } from "../../../../../../theme";

const FilterOfferType = (props) => {
    const { t } = useTranslation();
    const { className = "" } = props;
    const {
        offerTypesReferential,
        offerTypes,
        setOfferTypes,
        contractKeywords,
        onlyDisabilityCommittedEmployer,
        setOnlyDisabilityCommittedEmployer,
    } = React.useContext(OfferFiltersContext);

    const [offerTypeOptions, setOfferTypeOptions] = useState([]);

    useEffect(() => {
        const options = offerTypesReferential.reduce((acc, item) => {
            acc.push({
                id: item.id,
                name: item.title,
                value: `${item.id}`,
                className: "opt-offerTypes",
            });
            return acc;
        }, []);
        setOfferTypeOptions(options);
    }, [offerTypesReferential]);

    const handleChangeOfferType = (e) => {
        const { value } = e.target;
        if (offerTypes.findIndex((bd) => bd === value) === -1) {
            setOfferTypes([...offerTypes, value]);
        }
    };

    const removeKeyword = (type, id) => {
        setOfferTypes(offerTypes.filter((ot) => ot !== id.toString()));
    };

    const generateKeyword = (data) => {
        return (
            <li key={data.name} className="keyword-item">
                {data.name}
                <button type="button" className="remove-keyword-button" onClick={() => removeKeyword(data.type, data.id)}>
                    <PxdIcon iconName="delete" />
                </button>
            </li>
        );
    };

    return (
        <div className={`offer-types-filter-wrapper ${className}`}>
            <h3 className="title">{t("offer-list.my-contract")}</h3>
            <div className="filters">
                <div className="offer-types-filters">
                    <PxdCustomSelect
                        options={[
                            {
                                name: "Choississez un type d'offre",
                                value: "default",
                            },
                            ...offerTypeOptions,
                        ]}
                        onChange={handleChangeOfferType}
                        value="default"
                    />
                </div>
            </div>
            <div className="offer-disability-committed-employer">
                <PxdSwitch
                    activated={onlyDisabilityCommittedEmployer}
                    onClick={() => setOnlyDisabilityCommittedEmployer(!onlyDisabilityCommittedEmployer)}
                />
                {t("offer-list.filter-disability-committed-employer")}
            </div>
            {contractKeywords && contractKeywords.length > 0 && (
                <div className="keywords-wrapper">
                    <h4 className="title">{t("offer-list.my-keywords")}</h4>
                    <ul className="keywords">{contractKeywords.map(generateKeyword)}</ul>
                </div>
            )}
        </div>
    );
};

export default FilterOfferType;

