import React from "react";
import { useTranslation } from "react-i18next";
import OfferListHeader from "./internal-components/offer-list-header/offer-list-header";
import Footer from "../../../../theme/components/footer/footer";
import { OfferFiltersContext } from "./offers-filter-context";
import { AccessibilityContext } from "../../utils/accessibility-context";
import OfferListContents from "./internal-components/offer-list-contents/offer-list-contents";
import "./offer-list.scss";
import PxdLoader from "../../../../app-shared/components/stateless/pxd-loader/pxd-loader";

function OfferList() {
    const { t } = useTranslation();
    const { accessibilityIsActivated } = React.useContext(AccessibilityContext);
    const { offerCount, showLoader } = React.useContext(OfferFiltersContext);

    const title = (
        <>
            {t("offer-list.offer-list")}
            {offerCount !== undefined && <span className="red">{` (${offerCount})`}</span>}
        </>
    );

    return (
        <div className={`offer-list-page ${accessibilityIsActivated ? "accessibility-activated" : ""}`}>
            <OfferListHeader pageName={t("offer-list.your-search")} filters title={title} />
            {showLoader ? <PxdLoader showLoader={showLoader} customClass={"full"} /> : <OfferListContents />}
            <Footer />
        </div>
    );
}

export default OfferList;
