import queryString from "query-string";
import React, { createContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import OfferService from "../../../../config/services/offer.service";
import ReferentialService from "../../../../config/services/referential.service";

const OfferFiltersContext = createContext({});

function OfferFiltersProvider(props) {
    const history = useHistory();
    const location = useLocation();
    const { children } = props;
    const offset = 0;
    const pageSize = 10;
    const [offers, setOffers] = useState([]);
    const [offerCount, setOfferCount] = useState();
    const [offerSize, setOfferSize] = useState(pageSize);
    const [offerTypes, setOfferTypes] = useState([]);
    const [onlyDisabilityCommittedEmployer, setOnlyDisabilityCommittedEmployer] = useState(false);
    const [offerTypesReferential, setOfferTypesReferential] = useState([]);
    const [studyLevel, setStudyLevel] = useState();
    const [studyLevels, setStudyLevels] = useState([]);
    const [domains, setDomains] = useState([]);
    const [subDomainList, setSubDomainList] = useState([]);
    const [subBusinessDomains, setSubBusinessDomain] = useState([]);
    const [businessDomains, setBusinessDomains] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [regionsReferential, setRegionsReferential] = useState([]);
    const [departmentsReferential, setDepartmentsReferential] = useState([]);
    const [regions, setRegions] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [cities, setCities] = useState([]);
    const [abroad, setAbroad] = useState();
    const [sortOrder, setSortOrder] = useState();

    const [jobKeywords, setJobKeywords] = useState([]);
    const [locationKeywords, setLocationKeywords] = useState([]);
    const [studyLevelKeywords, setStudyLevelKeywords] = useState([]);
    const [contractKeywords, setContractKeywords] = useState([]);

    const debouncedGetOffers = useRef();
    const debouncedGetCount = useRef();

    const [reload, setReload] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const parsedStudyLevel = studyLevel !== -1 ? studyLevel : undefined;
        setShowLoader(true);
        if (debouncedGetOffers) {
            clearTimeout(debouncedGetOffers.current);
        }
        debouncedGetOffers.current = setTimeout(() => {
            OfferService.getOffers(
                {
                    offerTypes,
                    subBusinessDomains,
                    businessDomains,
                    jobs,
                    studyLevels: parsedStudyLevel,
                    cities,
                    departments,
                    regions,
                    abroad,
                    ...(onlyDisabilityCommittedEmployer ? { onlyDisabilityCommittedEmployer } : {}),
                },
                { field: "publishAt", order: sortOrder || "DESC" },
                { size: offerSize, offset },
            ).then((response) => {
                if (response?.data?.result) {
                    setOffers(response.data.result);
                    setShowLoader(false);
                }
            });
        }, 500);

        if (debouncedGetCount) {
            clearTimeout(debouncedGetCount.current);
        }
        debouncedGetCount.current = setTimeout(() => {
            OfferService.getOffersCount({
                offerTypes,
                subBusinessDomains,
                businessDomains,
                studyLevels: parsedStudyLevel,
                jobs,
                cities,
                departments,
                regions,
                abroad,
                ...(onlyDisabilityCommittedEmployer ? { onlyDisabilityCommittedEmployer } : {}),
            }).then((response) => {
                if (response?.data?.offerSize || response?.data?.offerSize === 0) {
                    setOfferCount(response.data.offerSize);
                }
            });
        });
    }, [
        offerSize,
        offset,
        offerTypes,
        businessDomains,
        subBusinessDomains,
        jobs,
        studyLevel,
        sortOrder,
        cities,
        departments,
        regions,
        abroad,
        onlyDisabilityCommittedEmployer,
    ]);

    // Initialize state from url
    useEffect(() => {
        const values = queryString.parse(location.search, {
            parseNumbers: false,
            parseBooleans: true,
            arrayFormat: "none",
        });
        if (reload && Object.keys(values).length === 0) {
            setOfferCount();
            setOffers([]);
            setOfferSize(pageSize);
            setOfferTypes([]);
            setStudyLevel();
            setSubBusinessDomain([]);
            setBusinessDomains([]);
            setJobs([]);
            setRegions([]);
            setDepartments([]);
            setCities([]);
            setAbroad();
            setOnlyDisabilityCommittedEmployer(false);
            setSortOrder();
        }
        if (values.pageSize) {
            setOfferSize(parseInt(values.pageSize, 10));
        }
        setOfferTypes(convertQueryParamToArray(values.offerTypes));
        setSubBusinessDomain(convertQueryParamToArray(values.subBusinessDomains));
        setBusinessDomains(convertQueryParamToArray(values.businessDomains));
        setJobs(convertQueryParamToArray(values.jobs));
        setDepartments(convertQueryParamToArray(values.departments));
        setRegions(convertQueryParamToArray(values.regions));
        setCities(convertQueryParamToArray(values.cities));
        if (values.studyLevels) {
            setStudyLevel(values.studyLevels);
        } else {
            setStudyLevel();
        }
        if (values.sortOrder) {
            setSortOrder(values.sortOrder);
        } else {
            setSortOrder();
        }
        setAbroad(values.abroad);
        setOnlyDisabilityCommittedEmployer(values.onlyDisabilityCommittedEmployer);
    }, [location.search, reload]);

    function convertQueryParamToArray(queryParam) {
        if (queryParam) {
            return Array.isArray(queryParam) ? queryParam : [queryParam];
        }
        return [];
    }

    // Url updater
    useEffect(() => {
        const newQueryObject = {};
        if (offerSize !== pageSize) {
            newQueryObject.pageSize = offerSize;
        }
        newQueryObject.offerTypes = offerTypes;
        newQueryObject.businessDomains = businessDomains;
        newQueryObject.subBusinessDomains = subBusinessDomains;
        newQueryObject.jobs = jobs;
        newQueryObject.cities = cities;
        newQueryObject.regions = regions;
        newQueryObject.departments = departments;
        newQueryObject.sortOrder = sortOrder;
        newQueryObject.abroad = abroad;
        newQueryObject.studyLevels = studyLevel;
        newQueryObject.offerTypes = newQueryObject.offerTypes.flat();
        newQueryObject.onlyDisabilityCommittedEmployer = onlyDisabilityCommittedEmployer ? true : undefined;
        history.push(`?${queryString.stringify(newQueryObject, { arrayFormat: "none" })}`);
    }, [
        history,
        offerSize,
        offerTypes,
        businessDomains,
        subBusinessDomains,
        jobs,
        studyLevel,
        sortOrder,
        cities,
        regions,
        departments,
        abroad,
        onlyDisabilityCommittedEmployer,
    ]);

    const fetchReferentials = () => {
        ReferentialService.getOfferTypes().then((res) => {
            if (res?.data) {
                const newDataFormat = [];
                res.data.map((offerType) => {
                    const ot = {
                        id: offerType.offerTypeId,
                        name: offerType.nameOfferType,
                        title: offerType.titleOfferType,
                        titleAbbr: offerType.titleAbbrOfferType,
                        value: false,
                    };
                    newDataFormat.push(ot);
                });
                setOfferTypesReferential(newDataFormat);
            }
        });
        ReferentialService.getBusinessDomain().then((res) => {
            if (res?.data) {
                setDomains(res.data);
            }
        });
        ReferentialService.getStudyLevels().then((res) => {
            if (res?.data) {
                setStudyLevels(res.data);
            }
        });
        ReferentialService.getDepartments().then((res) => {
            if (res?.data) {
                setDepartmentsReferential(res.data);
            }
        });
        ReferentialService.getRegions().then((res) => {
            if (res?.data) {
                setRegionsReferential(res.data);
            }
        });
    };

    const generateSubDomainList = () => {
        const subDomains = domains.reduce((acc, item) => {
            acc.push(...item.subBusinessDomains);
            return acc;
        }, []);
        setSubDomainList(subDomains);
    };

    useEffect(generateSubDomainList, [domains]);
    useEffect(fetchReferentials, []);

    // keywords for jobs
    useEffect(() => {
        const newKeywords = [];
        const bdl = Array.isArray(businessDomains) ? businessDomains : [businessDomains];
        bdl.forEach((bd) => {
            const bdId = bd.toString();
            const domain = domains.find((domainItem) => domainItem.id === bdId);
            if (domain) {
                newKeywords.push({
                    id: domain.id,
                    name: domain.name,
                    type: "domain",
                });
            }
        });
        const sbdl = Array.isArray(subBusinessDomains) ? subBusinessDomains : [subBusinessDomains];
        sbdl.forEach((sbd) => {
            const sbdId = sbd.toString();
            const subDomain = subDomainList.find((sdlItem) => sdlItem.id === sbdId);
            if (subDomain) {
                newKeywords.push({
                    id: subDomain.id,
                    name: subDomain.name,
                    type: "subdomain",
                });
            }
        });
        jobs.forEach((jb) => {
            newKeywords.push({
                id: jb,
                name: jb,
                type: "job",
            });
        });
        setJobKeywords(newKeywords);
    }, [jobs, subBusinessDomains, businessDomains, subDomainList, domains]);

    // keywords for location
    useEffect(() => {
        const newKeywords = [];
        regions.forEach((regionId) => {
            const foundRegion = regionsReferential.find((r) => r.id === regionId);
            if (foundRegion) {
                newKeywords.push({
                    id: foundRegion.id,
                    name: foundRegion.name,
                    type: "region",
                });
            }
        });
        departments.forEach((departmentId) => {
            const foundDepartment = departmentsReferential.find((d) => d.id === departmentId);
            if (foundDepartment) {
                newKeywords.push({
                    id: foundDepartment.id,
                    name: foundDepartment.name,
                    type: "department",
                });
            }
        });
        cities.forEach((cityName) => {
            newKeywords.push({
                id: cityName,
                name: cityName,
                type: "city",
            });
        });
        setLocationKeywords(newKeywords);
    }, [departmentsReferential, regionsReferential, regions, departments, cities]);

    // keywords for study level
    useEffect(() => {
        if (studyLevel && studyLevels) {
            setStudyLevelKeywords(studyLevels.filter((sl) => sl.id.toString() === studyLevel.toString()));
        } else {
            setStudyLevelKeywords([]);
        }
    }, [studyLevel, studyLevels]);

    // keywords for contracts
    useEffect(() => {
        const newKeywords = [];
        const otl = Array.isArray(offerTypes) ? offerTypes : [offerTypes];
        otl.forEach((offerType) => {
            const foundOfferType = offerTypesReferential.find((ot) => offerType && ot.id === parseInt(offerType));
            let isNotWritten = false;
            if (newKeywords.find((offerTypeAlreadyWritten) => foundOfferType.id === offerTypeAlreadyWritten.id) === undefined) {
                isNotWritten = true;
            }
            if (foundOfferType && isNotWritten) {
                newKeywords.push({
                    id: foundOfferType.id,
                    name: foundOfferType.title,
                    type: "offerType",
                });
            }
        });
        setContractKeywords(newKeywords);
    }, [offerTypesReferential, offerTypes]);

    return (
        <OfferFiltersContext.Provider
            value={{
                offset,
                pageSize,
                offers,
                setOffers,
                offerCount,
                offerSize,
                setOfferSize,
                offerTypes,
                setOfferTypes,
                offerTypesReferential,
                regions,
                setRegions,
                departments,
                setDepartments,
                domains,
                regionsReferential,
                departmentsReferential,
                jobs,
                sortOrder,
                setSortOrder,
                setJobs,
                cities,
                setCities,
                studyLevels,
                studyLevel,
                setStudyLevel,
                subBusinessDomains,
                setSubBusinessDomain,
                businessDomains,
                setBusinessDomains,
                subDomainList,
                jobKeywords,
                locationKeywords,
                studyLevelKeywords,
                contractKeywords,
                abroad,
                setAbroad,
                onlyDisabilityCommittedEmployer,
                setOnlyDisabilityCommittedEmployer,
                reload,
                setReload,
                showLoader,
            }}>
            {children}
        </OfferFiltersContext.Provider>
    );
}

export { OfferFiltersContext, OfferFiltersProvider };

