import React, { createContext, useState, useMemo } from "react";

const AccessibilityContext = createContext({});

function AccessibilityProvider(props) {
    const { children } = props;
    const [accessibilityIsActivated, setAccessibilityIsActivated] = useState(false);

    const toggleAccessibility = () => setAccessibilityIsActivated(!accessibilityIsActivated);

    const contextValue = useMemo(
        () => ({
            accessibilityIsActivated,
            toggleAccessibility,
        }),
        [accessibilityIsActivated],
    );

    return <AccessibilityContext.Provider value={contextValue}>{children}</AccessibilityContext.Provider>;
}

export { AccessibilityContext, AccessibilityProvider };
