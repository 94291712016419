import { useEffect } from "react";

const ScrollToTop = () => {
    useEffect(() => {
        if (!window.location.href.includes("#")) {
            window.scrollTo(0, 0);
        }
    }, []);
    return null;
};

export default ScrollToTop;
