import React from "react";

import { useTranslation } from "react-i18next";
import NotificationService from "../../notification/notification.service";

function PxdApplicationFormUpload(props) {
    const { file, fileInputRef, application, updateApplication } = props;

    const MAX_FILE_SIZE_BYTES = 3500000; // 3.5 MO in bytes
    const MAX_FILENAME_LENGTH = 50;
    const { t } = useTranslation();

    /**
     * Function that attaches a file to the application
     * @param newApplication
     * @param fileString : can take the values : "resume" or "cover-letter"
     * @param selectedFile
     * @returns {*}
     */
    function attachFile(newApplication, selectedFile) {
        const updatedApplication = { ...newApplication };
        if (file === "resume") {
            updatedApplication.resumeFile = selectedFile;
            updatedApplication.resumeFileName = selectedFile.name;
        } else if (file === "cover-letter") {
            updatedApplication.coverLetterFile = selectedFile;
            updatedApplication.coverLetterFileName = selectedFile.name;
        }

        return updatedApplication;
    }

    function handleFileUpload(e) {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            const fileNameLengthOK = selectedFile.name.length <= MAX_FILENAME_LENGTH;
            const fileSizeOK = selectedFile.size <= MAX_FILE_SIZE_BYTES;
            if (fileNameLengthOK && fileSizeOK) {
                let newApplication = { ...application };
                newApplication = attachFile(newApplication, selectedFile);
                updateApplication(newApplication);
            } else {
                fileInputRef.current.value = null;
                if (fileNameLengthOK && !fileSizeOK) {
                    NotificationService.error(t("application-form.form-status.error.file.size"));
                } else if (!fileNameLengthOK && fileSizeOK) {
                    NotificationService.error(t("application-form.form-status.error.file.name"));
                } else {
                    NotificationService.error(t("application-form.form-status.error.file.name"));
                    setTimeout(() => {
                        NotificationService.error(t("application-form.form-status.error.file.size"));
                    }, 1000);
                }
            }
        }
    }

    function handleFileSelect() {
        fileInputRef.current.click();
    }

    function getFile() {
        if (file === "resume") {
            return application.resumeFile;
        }
        return application.coverLetterFile;
    }

    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e)}
                accept=".pdf, .jpg, .png"
            />
            <button
                className="action-button"
                disabled={getFile()}
                onClick={() => {
                    handleFileSelect();
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill={getFile() ? "grey" : "#ff715b"}
                    className="bi bi-upload"
                    viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
            </button>
        </div>
    );
}

export default PxdApplicationFormUpload;
